import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class WeeklyChart extends Component {
  constructor(props) {
    super(props)

    const { currentWeekDates, seriesData } = this.getWeekData() // Get dynamic data for the week
    // Default dummy data for the current week
    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: [
          "#ff6347",
          "#3cb371",
          "#1e90ff",
          "#ff1493",
          "#32cd32",
          "#ff4500",
          "#8a2be2",
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            dataLabels: {
              show: false,
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: "#f8f8fa",
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: currentWeekDates, // Use dynamic Friday-to-Friday dates
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
      series: [{ name: "Data", data: seriesData }], // Dynamic series data
    }
  }

  // Function to calculate Friday to Friday dates for the current week (dynamic)
  getWeekData = () => {
    const now = new Date()

    // Calculate the previous Friday (last Friday)
    const lastFriday = this.getLastFriday(now)

    // Calculate dates from the Thursday before the last Friday to the Wednesday after the last Friday
    const dates = []
    const seriesData = []

    for (let i = -1; i < 6; i++) {
      const date = new Date(lastFriday)
      date.setDate(lastFriday.getDate() + i) // Add days from Thursday to Wednesday

      // Push formatted date into the dates array (day of the week)
      dates.push(this.formatDate(date))
      // Generate dummy data (you can replace this with actual data)
      seriesData.push(Math.floor(Math.random() * 30) + 10) // Random dummy data between 10-40
    }

    return { currentWeekDates: dates, seriesData }
  }

  // Function to get last Friday date from the current date
  getLastFriday = currentDate => {
    const dayOfWeek = currentDate.getDay()
    const daysToLastFriday =
      dayOfWeek === 5 ? 0 : dayOfWeek < 5 ? 7 + 5 - dayOfWeek : 5 - dayOfWeek

    const lastFriday = new Date(currentDate)
    lastFriday.setDate(currentDate.getDate() - daysToLastFriday)

    return lastFriday
  }

  // Format date to display (e.g., 'Thu 14')
  formatDate = date => {
    const options = { weekday: "short", day: "numeric" }
    return date.toLocaleDateString("en-US", options)
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="290"
        />
      </React.Fragment>
    )
  }
}

export default WeeklyChart
