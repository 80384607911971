import { VAT_COUNT, VAT_COUNT_API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  vatCount: 0,
}

const vatCount = (state = initialState, action) => {
  switch (action.type) {
    case VAT_COUNT:
      return {
        ...state,
        vatCount: action.payload,
      }
    case VAT_COUNT_API_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default vatCount
