import { COMPANY_COUNT, COMPANY_COUNT_API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  companyCount: 0,
}

const companyCount = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_COUNT:
      return {
        ...state,
        companyCount: action.payload,
      }
    case COMPANY_COUNT_API_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default companyCount
