import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class Barchart extends Component {
  constructor(props) {
    super(props)

    // Initial empty options setup (chart setup)
    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: [
          "#ff6347",
          "#3cb371",
          "#1e90ff",
          "#ff1493",
          "#32cd32",
          "#ff4500",
          "#8a2be2",
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            dataLabels: {
              show: false,
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: "#f8f8fa",
          row: {
            colors: ["transparent", "transparent"],
            opacity: 0.5,
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ], // Default to monthly categories
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
      // Default to monthly data
      series: this.formatMonthlyData(props.monthlyData || {}),
      viewOption: "month", // Default view option is 'month'
    }
  }

  // This function formats the monthly data
  formatMonthlyData = monthlyData => {
    const services = [
      "Premium Visa",
      "goAML",
      "Incorporate Domestic Company",
      "CRS-NIL",
      "FATCA-NIL",
      "Work Permit",
      "Residential VAT-Refund",
    ]

    return services.map(service => {
      const serviceData = monthlyData[service] || Array(12).fill(0) // Fallback to an array of 0s if no data for this service

      return {
        name: service,
        data: serviceData,
      }
    })
  }

  // Function to generate week start and end dates for the latest month
  generateWeekDates = () => {
    const weeks = []
    const now = new Date()
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)

    // Adjust to the start of the first week (Sunday)
    const firstDayOfWeek = new Date(startOfMonth)
    firstDayOfWeek.setDate(startOfMonth.getDate() - startOfMonth.getDay())

    let startOfWeek = new Date(firstDayOfWeek)

    // Loop through the month and generate weeks
    while (startOfWeek <= endOfMonth) {
      const endOfWeek = new Date(startOfWeek)
      endOfWeek.setDate(startOfWeek.getDate() + 6)

      if (endOfWeek > endOfMonth) {
        endOfWeek.setDate(endOfMonth.getDate()) // Ensure the end date doesn't go beyond the month
      }

      weeks.push({
        start: this.formatDate(startOfWeek),
        end: this.formatDate(endOfWeek),
      })

      // Move to the next week
      startOfWeek.setDate(startOfWeek.getDate() + 7)
    }

    return weeks
  }

  // Helper function to format the date in "DD-MMM" format
  formatDate = date => {
    const day = String(date.getDate()).padStart(2, "0")
    const month = date.toLocaleString("default", { month: "short" })
    return `${day}-${month}`
  }

  // Format the weekly data with start and end dates of the week
  formatWeeklyData = monthlyData => {
    const services = [
      "Premium Visa",
      "goAML",
      "Incorporate Domestic Company",
      "CRS-NIL",
      "FATCA-NIL",
      "Work Permit",
      "Residential VAT-Refund",
    ]

    const weeks = this.generateWeekDates() // Get the start and end dates of each week

    return services.map(service => {
      const serviceData = monthlyData[service] || Array(weeks.length).fill(0) // Fallback to the number of weeks with 0 values

      return {
        name: service,
        data: serviceData,
      }
    })
  }

  formatYearlyData = yearlyData => {
    const services = [
      "Premium Visa",
      "goAML",
      "Incorporate Domestic Company",
      "CRS-NIL",
      "FATCA-NIL",
      "Work Permit",
      "Residential VAT-Refund",
    ]

    // Get the current year
    const currentYear = new Date().getFullYear()

    // Generate the last 5 years dynamically
    const years = Array.from({ length: 5 }, (_, index) => currentYear - index)
    const sortedYears = years.reverse()
    // Process data for each service
    return services.map(service => {
      const serviceData = yearlyData[service] || {} // Get data for each service or empty object
      const formattedData = sortedYears.map(year => {
        // For each year, get the data or default to 0
        return serviceData[year] !== undefined ? serviceData[year] : 0
      })

      return {
        name: service,
        data: formattedData,
      }
    })
  }

  // Function to handle the change in view (month or year)
  handleViewChange = e => {
    const selectedView = e.target.value

    if (selectedView === "month") {
      // Update the chart for monthly data
      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ], // Monthly categories
          },
        },
        series: this.formatMonthlyData(this.props.monthlyData || {}),
        viewOption: "month", // Update the view option to month
      })
    } else if (selectedView === "week") {
      // Update the chart for weekly data (latest month)
      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.generateWeekDates().map(
              week => `${week.start} - ${week.end}`
            ), // Weekly categories with start and end dates
          },
        },
        series: this.formatWeeklyData(this.props.monthlyData || {}),
        viewOption: "week", // Update the view option to week
      })
    } else {
      this.setState({
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: Array.from(
              { length: 5 },
              (_, index) => new Date().getFullYear() - 4 + index
            ), // This ensures years are in ascending order
          },
        },
        series: this.formatYearlyData(this.props.yearlyData || {}),
        viewOption: "year", // Update the view option to year
      })
    }
  }

  // Ensure the component state is updated once the component has mounted
  componentDidMount() {
    const { monthlyData } = this.props
    console.log("monthlyData...", monthlyData)
    if (monthlyData) {
      this.setState({
        series: this.formatMonthlyData(monthlyData),
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="mb-3 w-25" style={{ marginLeft: "auto" }}>
          <select
            id="viewSelect"
            className="form-select"
            value={this.state.viewOption}
            onChange={this.handleViewChange}
          >
            <option value="year">Yearly</option>
            <option value="month">Monthly</option>
            {/* <option value="week">Weekly</option> */}
          </select>
        </div>

        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="290"
        />
      </React.Fragment>
    )
  }
}

export default Barchart
