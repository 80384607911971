import { call, put, takeEvery } from "redux-saga/effects"
import { goAMLApiError } from "./actions"
import { GOAML_COUNT } from "./actionTypes"

function* GOAMLCount({ payload }) {
    try {
    } catch (error) {
        yield put(goAMLApiError(error.message)) 
        if (error.message === "Token verification failed") {
            history.push("/login") 
        }
    }
}

function* goAmlSaga() {
    yield takeEvery(GOAML_COUNT, GOAMLCount)
}

export default goAmlSaga
