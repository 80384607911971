import { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Row, Table } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { ThreeDots } from "react-loader-spinner"
import "../Dashboard/services/datatables.scss"
import { MDBDataTable } from "mdbreact"
const Service = () => {
    const [userList, setUserList] = useState([])
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const navigate = useNavigate()
    const token = localStorage.getItem("Admintoken")
    const apiURL = process.env.REACT_APP_BACKEND_URL
    document.title = "Users | Admin & Dashboard"

    const fetchUserslist = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${apiURL}/api/admin/user/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const res = await response.json()
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                setUserList(res.data)
                console.log("res users list...............", res)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUserslist()
    }, [])

    const handleServices = (id, serviceName) => {
        console.log("id", id, serviceName)
        switch (serviceName) {
            case "goAML":
                let goAmlID = id
                navigate(`/service/details/${goAmlID}`)
                break
            case "Premium Visa":
                let visaID = id
                navigate(`/service/visa/details/${visaID}`)
                break
            case "Agreement And Contract":
                let agreementID = id
                navigate(`/service/agreement/details/${agreementID}`)
                break
            case "Incorporate Domestic Company":
                let companyID = id
                navigate(`/service/company/details/${companyID}`)
                break
            case "Work Permit":
                let permitID = id
                navigate(`/service/permit/details/${permitID}`)
                break
            case "Residential VAT-Refund":
                let vatID = id
                navigate(`/service/vat/details/${vatID}`)
                break
            case "FATCA-NIL":
                let fatcaNilID = id
                navigate(`/service/fatca/nil/details/${fatcaNilID}`)
                break
            case "CRS-NIL":
                let nilID = id
                navigate(`/service/crs/nil/details/${nilID}`)
                break
            case "FATCA-ACCOUNT":
                let fatcaAccountID = id
                navigate(`/service/fatca/account/details/${fatcaAccountID}`)
                break
            case "CRS-ACCOUNT":
                let accountID = id
                navigate(`/service/crs/account/details/${accountID}`)
                break
            default:
                break
        }
    }


    const rows = userList?.map((user, i) => ({
        ...user,
        ID: i + 1,
        fullName: user?.userId?.fullName, // Keep this for sorting
        displayFullName: (
            <p
                style={{ cursor: "pointer", color: "blue", margin: 0 }}
                onClick={() => handleServices(user?.products, user?.serviceName)}
            >
                {user?.userId?.fullName}
            </p>
        ),
        email: user?.userId?.email,
        Country: "Mauritius",
        createdAt: user.createdAt?.substring(0, 10),
    }))

    //datatables here.
    const data = {
        columns: [
            {
                label: "#ID",
                field: "ID",
                sort: "asc",
                width: 100,
            },
            {
                label: "Full Name",
                field: "fullName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Country",
                field: "Country",
                sort: "asc",
                width: 150,
            },
            {
                label: "Service Name",
                field: "serviceName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Create Date",
                field: "createdAt",
                sort: "asc",
                width: 150,
            },
        ],
        // rows,
        rows: rows.map(row => ({
            ...row,
            fullName: row.displayFullName, // Use displayFullName for rendering
        })),
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        maintitle="OnlineKorp"
                        title="Users"
                        breadcrumbItem="Users Services"
                    />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        {loading ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#6f42c1"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            <MDBDataTable responsive striped bordered data={data} />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Service
