import { call, put, takeEvery } from "redux-saga/effects"
import { visaApiError } from "./actions"
import { VISA_COUNT } from "./actionTypes"

function* VISACount({ payload }) {
  try {
  } catch (error) {
    yield put(visaApiError(error.message))
    if (error.message === "Token verification failed") {
      history.push("/login")
    }
  }
}

function* visaSaga() {
  yield takeEvery(VISA_COUNT, VISACount)
}

export default visaSaga
