import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Button, Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")
import html2pdf from "html2pdf.js"
import JSZip from "jszip"
import { saveAs } from "file-saver"

const WorkPermitDetails = () => {
  const { permitID } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL

  document.title = "Work Permit Service Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `${apiURL}/api/admin/permit/details/${permitID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      console.log("res permitID details;;;;;;;;;;;;;;;;;;;;;;;;;", res)
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        console.log("res premium visa details", res.data)
        setData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  const download = e => {
    e.preventDefault()

    const url = e.target.href
    console.log(url)

    fetch(url, {
      method: "GET",
      headers: {
        // You can add custom headers here if needed
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.arrayBuffer()
      })
      .then(buffer => {
        const blob = new Blob([buffer])
        const link = document.createElement("a")
        const fileName = url.split("/").pop() // Extract file name from URL
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName // Use the extracted file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Clean up
        window.URL.revokeObjectURL(link.href) // Release the object URL
      })
      .catch(err => {
        console.error("Download failed:", err)
      })
  }
  // Function to download PDF
  const downloadPDF = () => {
    const element = document.getElementById("pdf-content") // ID of the HTML element to be captured
    // Using html2pdf.js to generate and download the PDF
    const options = {
      filename: "service-details.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }
    html2pdf().from(element).set(options).save()
    downloadAllDocuments()
  }

  const downloadAllDocuments = async () => {
    const zip = new JSZip()
    // Define the files to download (person and organization documents)
    const filesToDownload = [
      {
        name: "certificateIndividualProofAddress",
        label: "Certificate Individual Proof of Address",
      },
      {
        name: "certificateIndividualLicence",
        label: "Certificate Individual Licence",
      },
      { name: "certificateIndividualBrn", label: "Certificate Individual BRN" },
      {
        name: "certificateIndividualPassportOrNid",
        label: "Certificate Individual Passport/Nid",
      },
      { name: "certificate_TAN", label: "Certificate TAN" },
      {
        name: "certificate_organisation_Brn",
        label: "Certificate Organisation BRN",
      },
      {
        name: "certificate_organisation_COI",
        label: "Certificate Organisation COI",
      },
      {
        name: "certificate_organisation_PassportOrNid",
        label: "Certificate Organisation Passport/NID",
      },
      {
        name: "certificate_organisation_Proof_Address",
        label: "Certificate Organisation Proof Address",
      },
      {
        name: "director_Authorisation_letter",
        label: "Director Authorisation Letter",
      },
    ]

    // Iterate through the files and add them to the zip
    for (let file of filesToDownload) {
      if (data?.documents?.[file.name]) {
        const fileUrl = data.documents[file.name]
        // Fetch the file
        const response = await fetch(fileUrl)
        // Get the Content-Type from the response headers
        const contentType = response.headers.get("Content-Type")
        // Determine file extension based on Content-Type
        let fileExtension = "pdf" // Default is pdf
        if (contentType.includes("image")) {
          // Check if it's an image, and determine the file extension (JPEG, PNG, etc.)
          if (contentType.includes("jpeg")) {
            fileExtension = "jpg"
          } else if (contentType.includes("png")) {
            fileExtension = "png"
          } else if (contentType.includes("gif")) {
            fileExtension = "gif"
          }
        }
        const blob = await response.blob()
        zip.file(`${file.label}.${fileExtension}`, blob)
      }
    }
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "Work-Permit-documents.zip")
    })
  }

  console.log("visa Data", data)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div
            className="mt-4"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button className="p-2" color="success" onClick={downloadPDF}>
              Download Form
            </Button>
          </div>
          <div className="user-details">
            <Row id="pdf-content">
              <Col lg={12}>
                <Card style={{ padding: "20px", marginTop: "20px" }}>
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>
                    Work Permit Service Details
                  </p>
                  {data ? (
                    <>
                      <Row>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          {" "}
                          <strong> Jurisdiction : </strong>{" "}
                          <span style={{ textAlign: "end" }}>
                            {data?.jurisdiction}{" "}
                          </span>
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong>Application Status By OnlineKorp : </strong>{" "}
                          {data?.active}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong>Application Form Status :</strong>{" "}
                          {data?.status}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong> Applied Date : </strong>
                          {data?.createdAt?.substring(0, 10)}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong>Work Permit Type : </strong>
                          {data?.permitType}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong> Work Permit Transaction Type :</strong>{" "}
                          {data?.transactionType}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div>
                      <p
                        colSpan="6"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        Applicant Details Not Available.
                      </p>
                    </div>
                  )}
                </Card>
                <Card style={{ padding: "20px" }}>
                  {data ? (
                    <>
                      <Row>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>
                          Person Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>First Name : </strong>
                          {data?.firstName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Middle Name : </strong>
                          {data?.middleName}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Last Name : </strong>
                          {data?.lastName}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Email : </strong>
                          {data?.email}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Gender : </strong>
                          {data?.gender}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Date of Birth : </strong>
                          {data?.dob}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Nationality : </strong>
                          {data?.nationality}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Marital Status : </strong>
                          {data?.marital_status}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Place Of Birth : </strong>
                          {data?.place_of_birth}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Date Of Entry : </strong>
                          {data?.date_of_entry}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Country : </strong>
                          {data?.country}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Applied Country : </strong>
                          {data?.appliedCountry}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "8px" }}
                        >
                          <strong>Job Title : </strong>
                          {data?.job_Title}
                        </Col>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Mauritius Contact Details
                        </p>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "5px" }}
                        >
                          <strong>Address : </strong>
                          {
                            data?.contact_detail?.mauritius_address_details
                              ?.mauritius_address
                          }
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "5px" }}
                        >
                          <strong>Phone No : </strong>
                          {data?.contact_detail?.phoneNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong> City : </strong>{" "}
                          {
                            data?.contact_detail?.mauritius_address_details
                              ?.city
                          }
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Country : </strong>{" "}
                          {
                            data?.contact_detail?.mauritius_address_details
                              ?.country
                          }
                        </Col>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Origin Country Contact Details
                        </p>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "5px" }}
                        >
                          <strong>Address : </strong>
                          {
                            data?.contact_detail?.origin_country_address_details
                              ?.country_address
                          }
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "5px" }}
                        >
                          <strong>Phone No : </strong>
                          {data?.contact_detail?.phoneNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong> City : </strong>{" "}
                          {
                            data?.contact_detail?.origin_country_address_details
                              ?.city
                          }
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Country : </strong>{" "}
                          {
                            data?.contact_detail?.origin_country_address_details
                              ?.country
                          }
                        </Col>

                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Passport Details
                        </p>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                            marginTop: "8px",
                          }}
                        >
                          <strong> Passport Number : </strong>{" "}
                          {data?.passport_Details?.passport_Number}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                            marginTop: "8px",
                          }}
                        >
                          <strong> Passport Issue Country : </strong>{" "}
                          {data?.passport_Details?.issue_Country}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                            marginTop: "8px",
                          }}
                        >
                          <strong> Passport Issue Date : </strong>{" "}
                          {data?.passport_Details?.issue_Date}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                            marginTop: "8px",
                          }}
                        >
                          <strong> Passport Expiry Date : </strong>{" "}
                          {data?.passport_Details?.expiry_Date}
                        </Col>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Package Information
                        </p>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Package Type : </strong>{" "}
                          {data?.subscriptionType}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Package Price : </strong> {data?.price}
                        </Col>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Applicant Nationality Details
                        </p>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Nationality : </strong>{" "}
                          {data?.applicant_nationality?.nationality}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Acquisition Date : </strong>{" "}
                          {data?.applicant_nationality?.acquisition_date}
                        </Col>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Applicant Renounced Nationality Details
                        </p>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Renounce Date : </strong>{" "}
                          {data?.applicant_renounced_nationality?.renounce_date}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Date Of Entry : </strong>{" "}
                          {data?.applicant_renounced_nationality?.date_of_entry}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Renounce Reason : </strong>{" "}
                          {
                            data?.applicant_renounced_nationality
                              ?.reason_renounce
                          }
                        </Col>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Employment Details
                        </p>

                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          <strong> Current Employment Details : </strong>
                        </p>

                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Company Name : </strong>{" "}
                          {data?.employment_details?.current?.company_name}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Post Name : </strong>{" "}
                          {data?.employment_details?.current?.post_name}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Start Date : </strong>{" "}
                          {data?.employment_details?.current?.start_date}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> End Date : </strong>{" "}
                          {data?.employment_details?.current?.end_date}
                        </Col>

                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "10px",
                          }}
                        >
                          <strong> Recent Employment Details : </strong>
                        </p>

                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Company Name : </strong>{" "}
                          {data?.employment_details?.recent?.company_name}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Post Name : </strong>{" "}
                          {data?.employment_details?.recent?.post_name}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Start Date : </strong>{" "}
                          {data?.employment_details?.recent?.start_date}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> End Date : </strong>{" "}
                          {data?.employment_details?.recent?.end_date}
                        </Col>

                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginTop: "20px",
                          }}
                        >
                          Investment Details
                        </p>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> General Nature Activity : </strong>{" "}
                          {data?.general_nature_activity}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Approved Market : </strong>{" "}
                          {data?.approved_market}
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong>
                            {" "}
                            % of export (Mixed Domestic & Export) :{" "}
                          </strong>{" "}
                          {data?.export_percentage} %
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Proposed Amount To Be Transferred : </strong>{" "}
                          {
                            data?.investment_details?.proposed_Amount
                              ?.proposed_Amount_Transferred
                          }
                        </Col>
                        <Col
                          lg={6}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Currency : </strong>{" "}
                          {data?.investment_details?.proposed_Amount?.currency}
                        </Col>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "10px",
                          }}
                        >
                          <strong> Proposed Investment(MUR) : </strong>
                        </p>
                        <Col
                          lg={4}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> FDI : </strong>{" "}
                          {data?.investment_details?.proposed_Investment?.FDI}
                        </Col>
                        <Col
                          lg={4}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Local : </strong>{" "}
                          {data?.investment_details?.proposed_Investment?.local}
                        </Col>
                        <Col
                          lg={4}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Total : </strong>{" "}
                          {data?.investment_details?.proposed_Investment?.total}
                        </Col>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "10px",
                          }}
                        >
                          <strong> Estimated Annual Income (MUR) : </strong>
                        </p>
                        <Col
                          lg={4}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Year One : </strong>{" "}
                          {
                            data?.investment_details?.estimate_annual_turnover
                              ?.year_One
                          }
                        </Col>
                        <Col
                          lg={4}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Year Two : </strong>{" "}
                          {
                            data?.investment_details?.estimate_annual_turnover
                              ?.year_Two
                          }
                        </Col>
                        <Col
                          lg={4}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          <strong> Year Three : </strong>{" "}
                          {
                            data?.investment_details?.estimate_annual_turnover
                              ?.year_Third
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Card style={{ padding: "20px", marginTop: "20px" }}>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              marginTop: "20px",
                            }}
                          >
                            Documents
                          </p>
                          <Col lg={8}>
                            {data?.documents
                              ?.Certificate_Character_Police_Clearance && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    <strong>
                                      Certificate of Character /Police Clearance
                                      covering the last ten years (less than 6
                                      months) :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.Certificate_Character_Police_Clearance
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.Certificate_Character_Police_Clearance
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.birthCertificate && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    {" "}
                                    <strong>Birth Certificate</strong> :{" "}
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={data?.documents?.birthCertificate}
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={data.documents?.birthCertificate}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.cancellationObjectionsLetter && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col lg={8}>
                                  <p>
                                    <strong>
                                      Cancellation And No Objections Letter :
                                    </strong>
                                  </p>
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.cancellationObjectionsLetter
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data.documents
                                        ?.cancellationObjectionsLetter
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents
                              ?.investment_Evidance_bank_Account && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    {" "}
                                    <strong>
                                      Investment (evidence of transfer of funds
                                      from abroad in a local bank account of the
                                      retired non citizen in Mauritius) :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.investment_Evidance_bank_Account
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.investment_Evidance_bank_Account
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.marriage_Divorce_Certificate && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    {" "}
                                    <strong>
                                      Marriage Certificate / Divorce Certificate
                                      :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.marriage_Divorce_Certificate
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.marriage_Divorce_Certificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.medicalCertificate && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    <strong>
                                      Medical Certificate & Reports (less than
                                      six month old) :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={data?.documents?.medicalCertificate}
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={data?.documents?.medicalCertificate}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.occupation_Work_Permit && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    {" "}
                                    <strong>
                                      Copy of occupation work
                                      permit/work/Residence permit :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents?.occupation_Work_Permit
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents?.occupation_Work_Permit
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.passportBioData && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    {" "}
                                    <strong>
                                      Passport - Bio-Data last entry visa pages
                                      (if applicable is already in mauritius) :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={data?.documents?.passportBioData}
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={data?.documents?.passportBioData}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.passportSizePhotograph && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    <strong>
                                      Recent Colour passport size of digital
                                      Photograph of
                                      3.5cm(413pixels)x4.5cm(531pixels) (less
                                      than six month old) :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents?.passportSizePhotograph
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents?.passportSizePhotograph
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.security_Health_Certificate && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Col lg={8}>
                                  {" "}
                                  <p>
                                    {" "}
                                    <strong>
                                      Security/Health Certificate :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.security_Health_Certificate
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.security_Health_Certificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                            {data?.documents?.signed_Undertaking_Applicant && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col lg={8}>
                                  <p>
                                    <strong>
                                      Signed undertaking - Applicant :
                                    </strong>
                                  </p>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.signed_Undertaking_Applicant
                                    }
                                    download
                                    onClick={e => download(e)}
                                  >
                                    Download
                                  </a>{" "}
                                </Col>
                                <Col lg={2}>
                                  {" "}
                                  <a
                                    href={
                                      data?.documents
                                        ?.signed_Undertaking_Applicant
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Document
                                  </a>
                                </Col>
                              </div>
                            )}
                          </Col>
                        </Card>
                      </Row>
                    </>
                  ) : (
                    <div>
                      <p
                        colSpan="6"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        Service Details Not Available.
                      </p>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkPermitDetails
