import { useEffect, useState } from "react"
import { Button, Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")
import html2pdf from "html2pdf.js"
import JSZip from "jszip"
import { saveAs } from "file-saver"

const GoAmlDetails = () => {
  const { goAmlID } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL

  document.title = "GoAML Service Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `${apiURL}/api/admin/goaml/details/${goAmlID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      console.log("goAmlID details", res)
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        console.log("res premium visa details", res.data)
        setData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  const download = e => {
    e.preventDefault()

    const url = e.target.href
    console.log(url)

    fetch(url, {
      method: "GET",
      headers: {
        // You can add custom headers here if needed
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.arrayBuffer()
      })
      .then(buffer => {
        const blob = new Blob([buffer])
        const link = document.createElement("a")
        const fileName = url.split("/").pop() // Extract file name from URL
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName // Use the extracted file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Clean up
        window.URL.revokeObjectURL(link.href) // Release the object URL
      })
      .catch(err => {
        console.error("Download failed:", err)
      })
  }

  // Function to download PDF
  const downloadPDF = () => {
    const element = document.getElementById("pdf-content") // ID of the HTML element to be captured
    // Using html2pdf.js to generate and download the PDF
    const options = {
      filename: "service-details.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }
    html2pdf().from(element).set(options).save();
    downloadAllDocuments();
  }

  const downloadAllDocuments = async () => {
    const zip = new JSZip()
    // Define the files to download (person and organization documents)
    const filesToDownload = [
      {
        name: "certificateIndividualProofAddress",
        label: "Certificate Individual Proof of Address",
      },
      {
        name: "certificateIndividualLicence",
        label: "Certificate Individual Licence",
      },
      { name: "certificateIndividualBrn", label: "Certificate Individual BRN" },
      {
        name: "certificateIndividualPassportOrNid",
        label: "Certificate Individual Passport/Nid",
      },
      { name: "certificate_TAN", label: "Certificate TAN" },
      {
        name: "certificate_organisation_Brn",
        label: "Certificate Organisation BRN",
      },
      {
        name: "certificate_organisation_COI",
        label: "Certificate Organisation COI",
      },
      {
        name: "certificate_organisation_PassportOrNid",
        label: "Certificate Organisation Passport/NID",
      },
      {
        name: "certificate_organisation_Proof_Address",
        label: "Certificate Organisation Proof Address",
      },
      {
        name: "director_Authorisation_letter",
        label: "Director Authorisation Letter",
      },
    ]

    // Iterate through the files and add them to the zip
    for (let file of filesToDownload) {
      if (data?.documents?.[file.name]) {
        const fileUrl = data.documents[file.name]
        // Fetch the file
        const response = await fetch(fileUrl)
        // Get the Content-Type from the response headers
        const contentType = response.headers.get("Content-Type")
        // Determine file extension based on Content-Type
        let fileExtension = "pdf" // Default is pdf
        if (contentType.includes("image")) {
          // Check if it's an image, and determine the file extension (JPEG, PNG, etc.)
          if (contentType.includes("jpeg")) {
            fileExtension = "jpg"
          } else if (contentType.includes("png")) {
            fileExtension = "png"
          } else if (contentType.includes("gif")) {
            fileExtension = "gif"
          }
        }
        const blob = await response.blob()
        zip.file(`${file.label}.${fileExtension}`, blob)
      }
    }
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "goAml-documents.zip")
    })
  }

  console.log("visa Data", data)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div
            className="mt-4"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button className="p-2" color="success" onClick={downloadPDF}>
              Download Form
            </Button>
          </div>
          <div className="user-details">
            <Row id="pdf-content">
              <Col lg={12}>
                <Card style={{ padding: "20px", marginTop: "20px" }}>
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>
                    GoAML Service Details
                  </p>
                  {data ? (
                    <>
                      <Row>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          {" "}
                          <strong> Jurisdiction : </strong>{" "}
                          <span style={{ textAlign: "end" }}>
                            {data?.jurisdiction}{" "}
                          </span>
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          {" "}
                          <strong>
                            Application Status By OnlineKorp :{" "}
                          </strong>{" "}
                          {data?.active}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong>Application Form Status :</strong>{" "}
                          {data?.status}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong> Applied Date : </strong>
                          {data?.createdAt?.substring(0, 10)}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div>
                      <p
                        colSpan="6"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        Applicant Details Not Available.
                      </p>
                    </div>
                  )}
                </Card>
                <Card style={{ padding: "20px" }}>
                  {data ? (
                    <>
                      {data?.registrationType == "Organisation" ? (
                        <>
                          <Row>
                            <p
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              Organisation Information
                            </p>
                            <Col
                              lg={6}
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong> Full Name MLRO : </strong>{" "}
                              {data?.fullNameMLRO}
                            </Col>
                            <Col lg={6} style={{ fontSize: "16px" }}>
                              <strong>Organisation Business Type : </strong>{" "}
                              {data?.organisationBusinessType}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Registration Type : </strong>
                              {data?.registrationType}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Institution Name : </strong>
                              {data?.institutionName}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Incorporation Number : </strong>
                              {data?.incorporationNumber}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Incorporation Legal Form : </strong>
                              {data?.incorporationLegalForm}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Incorporation Legal Form : </strong>
                              {data?.incorporationLegalForm}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Organisation Phone Number :</strong>
                              {data?.phoneNumber}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>TAN Number : </strong>
                              {data?.tan}
                            </Col>
                            <Col
                              lg={12}
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              Contact Details
                            </Col>
                            <Col
                              lg={6}
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              <strong> Phone Number : </strong>{" "}
                              {data?.phoneNumber}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "10px" }}
                            >
                              <strong>Address : </strong>{" "}
                              {data?.organisationAddressDetails?.address}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "5px" }}
                            >
                              <strong>City : </strong>
                              {data?.organisationAddressDetails?.city}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "5px" }}
                            >
                              <strong>Town : </strong>
                              {data?.organisationAddressDetails?.town}
                            </Col>
                            <Col
                              lg={12}
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              MLRO Person Details
                            </Col>
                            <Col
                              lg={6}
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              <strong> First Name : </strong>{" "}
                              {data?.mlroPersonDetails?.firstName}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "10px" }}
                            >
                              <strong>Last Name : </strong>{" "}
                              {data?.mlroPersonDetails?.lastName}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Email : </strong>
                              {data?.mlroPersonDetails?.email}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Occupation : </strong>
                              {data?.mlroPersonDetails?.occupation}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Gender : </strong>{" "}
                              {data?.mlroPersonDetails?.gender}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Date of Birth : </strong>
                              {data?.mlroPersonDetails?.dob}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Title : </strong>
                              {data?.mlroPersonDetails?.title}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>NIC : </strong>
                              {data?.mlroPersonDetails?.nic}
                            </Col>
                            <Col
                              lg={12}
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              MLRO Contact Details
                            </Col>
                            <Col
                              lg={6}
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              <strong> Phone Number : </strong>{" "}
                              {
                                data?.mlroPersonDetails?.contactMLRODetails
                                  ?.phoneNumber
                              }
                            </Col>
                            <Col
                              lg={12}
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              Passport Details
                            </Col>
                            <Col
                              lg={12}
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              <strong> Do you have passport? : </strong>{" "}
                              {
                                data?.mlroPersonDetails?.passportDetails
                                  ?.isPassport
                              }
                            </Col>
                            {data?.mlroPersonDetails?.passportDetails
                              ?.isPassport == "yes" && (
                              <>
                                <Col
                                  lg={6}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <strong> Passport Number : </strong>{" "}
                                  {
                                    data?.mlroPersonDetails?.passportDetails
                                      ?.passportNumber
                                  }
                                </Col>
                                <Col
                                  lg={6}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <strong> Passport Country : </strong>{" "}
                                  {
                                    data?.mlroPersonDetails?.passportDetails
                                      ?.passportCountry
                                  }
                                </Col>
                              </>
                            )}
                            <Col
                              lg={12}
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              Package Information
                            </Col>
                            <Col
                              lg={6}
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              <strong> Package Type : </strong>{" "}
                              {data?.package?.packageType}
                            </Col>
                            <Col
                              lg={6}
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              <strong> Package Price : </strong>{" "}
                              {data?.package?.packagePrice}
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "20px" }}>
                            <Card>
                              <Col
                                lg={12}
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  marginTop: "10px",
                                }}
                              >
                                Documents
                              </Col>
                              <Col lg={8} style={{ marginTop: "10px" }}>
                                <div>
                                  {data?.documents?.certificate_TAN && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      <Col lg={4}>
                                        {" "}
                                        <p>
                                          <strong> Certificate TAN</strong>:{" "}
                                        </p>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents?.certificate_TAN
                                          }
                                          download
                                          onClick={e => download(e)}
                                        >
                                          Download
                                        </a>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents?.certificate_TAN
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )}
                                  {data?.documents
                                    ?.certificate_organisation_Brn && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      <Col lg={4}>
                                        {" "}
                                        <p>
                                          {" "}
                                          <strong>
                                            {" "}
                                            Certificate Organisation BRN
                                          </strong>{" "}
                                          :{" "}
                                        </p>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.certificate_organisation_Brn
                                          }
                                          download
                                          onClick={e => download(e)}
                                        >
                                          Download
                                        </a>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data.documents
                                              ?.certificate_organisation_Brn
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )}
                                  {data?.documents
                                    ?.certificate_organisation_COI && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      <Col lg={4}>
                                        {" "}
                                        <p>
                                          {" "}
                                          <strong>
                                            {" "}
                                            Certificate Organisation COI
                                          </strong>{" "}
                                          :{" "}
                                        </p>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.certificate_organisation_COI
                                          }
                                          download
                                          onClick={e => download(e)}
                                        >
                                          Download
                                        </a>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data.documents
                                              ?.certificate_organisation_COI
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )}
                                  {data?.documents
                                    ?.certificate_organisation_PassportOrNid && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      <Col lg={4}>
                                        {" "}
                                        <p>
                                          {" "}
                                          <strong>
                                            {" "}
                                            Certificate Organisation
                                            Passport/NID
                                          </strong>{" "}
                                          :{" "}
                                        </p>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.certificate_organisation_PassportOrNid
                                          }
                                          download
                                          onClick={e => download(e)}
                                        >
                                          Download
                                        </a>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.certificate_organisation_PassportOrNid
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )}
                                  {data?.documents
                                    ?.certificate_organisation_Proof_Address && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      <Col lg={4}>
                                        {" "}
                                        <p>
                                          {" "}
                                          <strong>
                                            {" "}
                                            Certificate Organisation Proof
                                            Address
                                          </strong>{" "}
                                          :{" "}
                                        </p>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.certificate_organisation_Proof_Address
                                          }
                                          download
                                          onClick={e => download(e)}
                                        >
                                          Download
                                        </a>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.certificate_organisation_Proof_Address
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )}
                                  {data?.documents
                                    ?.director_Authorisation_letter && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {" "}
                                      <Col lg={4}>
                                        {" "}
                                        <p>
                                          {" "}
                                          <strong>
                                            {" "}
                                            Director Authorisation Letter
                                          </strong>{" "}
                                          :{" "}
                                        </p>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.director_Authorisation_letter
                                          }
                                          download
                                          onClick={e => download(e)}
                                        >
                                          Download
                                        </a>{" "}
                                      </Col>
                                      <Col lg={4}>
                                        {" "}
                                        <a
                                          href={
                                            data?.documents
                                              ?.director_Authorisation_letter
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Card>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                              Person Details
                            </p>
                            <Col lg={6} style={{ fontSize: "16px" }}>
                              <strong>First Name : </strong>
                              {data?.firstName}
                            </Col>
                            <Col lg={6} style={{ fontSize: "16px" }}>
                              <strong>Last Name : </strong>
                              {data?.lastName}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>BRN Number : </strong>
                              {data?.brnNumber}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Email : </strong>
                              {data?.email}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Gender : </strong>
                              {data?.gender}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "8px" }}
                            >
                              <strong>Date of Birth : </strong>
                              {data?.dob}
                            </Col>
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              Contact Details
                            </p>
                            <Col lg={6} style={{ fontSize: "16px" }}>
                              <strong> Phone Number : </strong>{" "}
                              {data?.contactDetails?.phoneNumber}
                            </Col>
                            <Col lg={6} style={{ fontSize: "16px" }}>
                              <strong>Address : </strong>{" "}
                              {data?.addressDetails?.address}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "5px" }}
                            >
                              <strong>City : </strong>
                              {data?.addressDetails?.city}
                            </Col>
                            <Col
                              lg={6}
                              style={{ fontSize: "16px", marginTop: "5px" }}
                            >
                              <strong>Town : </strong>
                              {data?.addressDetails?.town}
                            </Col>
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "20px",
                              }}
                            >
                              Passport Details
                            </p>
                            <Col
                              lg={12}
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong> Do you have passport ? : </strong>{" "}
                              {data?.passportDetails?.isPassport}
                            </Col>
                            {data?.passportDetails?.isPassport == "yes" && (
                              <>
                                <Col
                                  lg={6}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <strong> Passport Number : </strong>{" "}
                                  {data?.passportDetails?.passportNumber}
                                </Col>
                                <Col
                                  lg={6}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <strong> Passport Country : </strong>{" "}
                                  {data?.passportDetails?.passportCountry}
                                </Col>
                                <p
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    marginTop: "20px",
                                  }}
                                >
                                  Package Information
                                </p>
                                <Col
                                  lg={6}
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <strong> Package Type : </strong>{" "}
                                  {data?.package?.packageType}
                                </Col>
                                <Col
                                  lg={6}
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <strong> Package Price : </strong>{" "}
                                  {data?.package?.packagePrice}
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            <Card
                              style={{ padding: "20px", marginTop: "20px" }}
                            >
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  marginTop: "20px",
                                }}
                              >
                                Documents
                              </p>
                              <Col lg={8}>
                                {data?.documents
                                  ?.certificateIndividualProofAddress && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {" "}
                                    <Col lg={4}>
                                      {" "}
                                      <p>
                                        <strong>
                                          {" "}
                                          Certificate Of Individual Proof
                                          Address
                                        </strong>
                                        :{" "}
                                      </p>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data?.documents
                                            ?.certificateIndividualProofAddress
                                        }
                                        download
                                        onClick={e => download(e)}
                                      >
                                        Download
                                      </a>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data?.documents
                                            ?.certificateIndividualProofAddress
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View Document
                                      </a>
                                    </Col>
                                  </div>
                                )}
                                {data?.documents
                                  ?.certificateIndividualLicence && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {" "}
                                    <Col lg={4}>
                                      {" "}
                                      <p>
                                        {" "}
                                        <strong>
                                          Certificate Individual Licence
                                        </strong>{" "}
                                        :{" "}
                                      </p>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data?.documents
                                            ?.certificateIndividualLicence
                                        }
                                        download
                                        onClick={e => download(e)}
                                      >
                                        Download
                                      </a>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data.documents
                                            ?.certificateIndividualLicence
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View Document
                                      </a>
                                    </Col>
                                  </div>
                                )}
                                {data?.documents?.certificateIndividualBrn && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {" "}
                                    <Col lg={4}>
                                      {" "}
                                      <p>
                                        {" "}
                                        <strong>
                                          {" "}
                                          Certificate Individual BRN
                                        </strong>{" "}
                                        :{" "}
                                      </p>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data?.documents
                                            ?.certificateIndividualBrn
                                        }
                                        download
                                        onClick={e => download(e)}
                                      >
                                        Download
                                      </a>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data.documents
                                            ?.certificateIndividualBrn
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View Document
                                      </a>
                                    </Col>
                                  </div>
                                )}
                                {data?.documents
                                  ?.certificateIndividualPassportOrNid && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {" "}
                                    <Col lg={4}>
                                      {" "}
                                      <p>
                                        {" "}
                                        <strong>
                                          {" "}
                                          Certificate Individual Passport/NID
                                        </strong>{" "}
                                        :{" "}
                                      </p>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data?.documents
                                            ?.certificateIndividualPassportOrNid
                                        }
                                        download
                                        onClick={e => download(e)}
                                      >
                                        Download
                                      </a>{" "}
                                    </Col>
                                    <Col lg={4}>
                                      {" "}
                                      <a
                                        href={
                                          data?.documents
                                            ?.certificateIndividualPassportOrNid
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View Document
                                      </a>
                                    </Col>
                                  </div>
                                )}
                              </Col>
                            </Card>
                          </Row>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <p
                        colSpan="6"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        Service Details Not Available.
                      </p>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default GoAmlDetails
