import React from "react"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//Utility
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Users from "pages/Users"
import Service from "pages/service"
import ServiceDetails from "pages/ServiceDetails"
import GoAML from "pages/Dashboard/goAML/GoAML"
import GoAmlDetails from "pages/GoAmlDetails"
import { components } from "react-select"
import Company from "pages/Dashboard/company/Company"
import CompanyDetail from "pages/CompanyDetail"
import Visa from "pages/Dashboard/visa/Visa"
import VisaDetail from "pages/VisaDetails"
import WorkPermit from "pages/Dashboard/workPermit"
import WorkPermitDetails from "pages/WorkPermitDetails"
import Nil from "pages/Dashboard/Fatca/Nil/Nil"
import CRSNil from "pages/Dashboard/CRS/Nil/CRSNil"
import FatcaDetail from "pages/FatcaDetail"
import CRSDetail from "pages/CRSDetail"
import Account from "pages/Dashboard/Fatca/Account/Account"
import CRSAccount from "pages/Dashboard/CRS/Account/CRSAccount"
import FatcaAccountDetail from "pages/FatcaAccountDetail"
import CRSAccountDetail from "pages/CRSAccountDetail"
import Contact from "pages/Contact"
import VAT from "pages/Dashboard/VAT"
import VATDetail from "pages/VATDetail.js"
import Agreements from "pages/Dashboard/agreements"
import AgreementDetail from "pages/AgreementDetail"
import ContactDetail from "pages/ContactDetail"
import KYC from "pages/Dashboard/KYC/KYC"
import KYCDetail from "pages/KYCDetail"


const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/users", component: <Users /> },
  { path: "/contact", component: <Contact /> },
  { path: "/service/:id", component: <Service /> },
  // { path: "/service/details/:visaID", component: <ServiceDetails /> },

  //services
  { path: "/services/goAml", component: <GoAML /> },
  { path: "/services/KYC", component: <KYC /> },
  { path: "/services/vat", component: <VAT /> },
  { path: "/services/agreements", component: <Agreements /> },
  { path: "/services/company", component: <Company /> },
  { path: "/services/visa", component: <Visa /> },
  { path: "/services/fatca/nil", component: <Nil /> },
  { path: "/services/crs/nil", component: <CRSNil /> },
  { path: "/services/fatca/account", component: <Account /> },
  { path: "/services/crs/account", component: <CRSAccount /> },
  { path: "/work/permit", component: <WorkPermit /> },
  { path: "/service/fatca/nil/details/:fatcaNilID", component: <FatcaDetail /> },
  { path: "/service/crs/nil/details/:nilID", component: <CRSDetail /> },
  { path: "/service/fatca/account/details/:fatcaAccountID", component: <FatcaAccountDetail /> },
  { path: "/service/crs/account/details/:accountID", component: <CRSAccountDetail /> },
  { path: "/service/details/:goAmlID", component: <GoAmlDetails /> },
  { path: "/service/KYC/details/:KYCID", component: <KYCDetail /> },
  { path: "/service/vat/details/:vatID", component: <VATDetail /> },
  { path: "/service/agreement/details/:agreementID", component: <AgreementDetail /> },
  { path: "/service/visa/details/:visaID", component: <VisaDetail /> },
  { path: "/service/company/details/:companyID", component: <CompanyDetail /> },
  { path: "/service/permit/details/:permitID", component: <WorkPermitDetails /> },
  { path: "/contact/details/:userId", component: <ContactDetail /> },

  //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "*", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { userRoutes, authRoutes }
