import { PERMIT_COUNT, PERMIT_COUNT_API_ERROR } from "./actionTypes"

export const permitCount = permitcount => {
  console.log("user count details here.", permitcount)
  return {
    type: PERMIT_COUNT,
    payload: permitcount,
  }
}

export const permitApiError = error => {
  return {
    type: PERMIT_COUNT_API_ERROR,
    payload: error,
  }
}
