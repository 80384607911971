import { VISA_COUNT, VISA_COUNT_API_ERROR } from "./actionTypes"

export const visaCount = visacount => {
  console.log("user count details here.", visacount)
  return {
    type: VISA_COUNT,
    payload: visacount,
  }
}

export const visaApiError = error => {
  return {
    type: VISA_COUNT_API_ERROR,
    payload: error,
  }
}
