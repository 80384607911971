import { call, put, takeEvery } from "redux-saga/effects"
import { permitApiError } from "./actions"
import { PERMIT_COUNT } from "./actionTypes"

function* PERMITCount({ payload }) {
  try {
  } catch (error) {
    yield put(permitApiError(error.message))
    if (error.message === "Token verification failed") {
      history.push("/login")
    }
  }
}

function* permitSaga() {
  yield takeEvery(PERMIT_COUNT, PERMITCount)
}

export default permitSaga
