import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  CardBody,
} from "reactstrap"

import "chartist/dist/scss/chartist.scss"

// Charts
import LineApexChart from "../AllCharts/chartapex"
import DonutChart from "../AllCharts/dountchart"
import BarApexChart from "../AllCharts/barchart"
import AreaChart from "../AllCharts/areachart"

//i18n
import { withTranslation } from "react-i18next"

import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import { Link } from "react-router-dom"
import WeeklyChart from "pages/AllCharts/weeklychart"



const Dashboard = props => {
  document.title = "OnlineKorp | Admin & Dashboard"
  const token = localStorage.getItem("Admintoken")
  const apiURL = process.env.REACT_APP_BACKEND_URL
  const [menu, setMenu] = useState(false)
  const [dashboardInfo, setDashboardInfo] = useState({})
  const [totalComplete, setTotalComplete] = useState(0)
  const [totalIncomplete, setTotalIncomplete] = useState(0)

  const toggle = () => {
    setMenu(!menu)
  }

  const fetchDashboardInfo = async () => {
    const response = await fetch(`${apiURL}/api/admin/dashboard/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }
    const res = await response.json()
    if (res.message === "Token verification failed") {
      // You might want to handle navigation in a different way
      throw new Error("Token verification failed")
    }
    if (res?.data?.servicesDetail?.length > 0) {
      let tempTotalComplete = 0
      let tempTotalIncomplete = 0
      res?.data?.servicesDetail?.forEach(element => {
        element?.statuses?.forEach(statusObj => {
          if (statusObj?.status === "Incomplete") {
            tempTotalIncomplete += Number(statusObj?.count)
          } else if (statusObj?.status === "Completed") {
            tempTotalComplete += Number(statusObj?.count)
          }
        })
      })
      setTotalIncomplete(tempTotalIncomplete)
      setTotalComplete(tempTotalComplete)
    }
    setDashboardInfo(res?.data)
  }

  useEffect(() => {
    fetchDashboardInfo()
  }, [])

  console.log(
    "dashboard data.",
    dashboardInfo,
    totalIncomplete,
    totalComplete,
    dashboardInfo?.yearlyData,
    dashboardInfo?.monthlyData
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to OnlineKorp Dashboard
                  </li>
                </ol>
              </Col>

              {/* <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Dropdown isOpen={menu} toggle={toggle}>
                    <DropdownToggle
                      color="primary"
                      className="btn btn-primary dropdown-toggle waves-effect waves-light"
                    >
                      <i className="mdi mdi-cog me-2"></i> Settings
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem tag="a" href="#">
                        Action
                      </DropdownItem>
                      <DropdownItem tag="a" href="#">
                        Another action
                      </DropdownItem>
                      <DropdownItem tag="a" href="#">
                        Something else here
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="a" href="#">
                        Separated link
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col> */}
            </Row>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Users
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {dashboardInfo?.usersCount > 0
                        ? dashboardInfo?.usersCount
                        : 0}{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total Service Type
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {dashboardInfo?.servicesDetail?.length > 0
                        ? dashboardInfo?.servicesDetail?.length
                        : 0}{" "}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Completed Service
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {totalComplete}{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Incomplete Services
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {totalIncomplete}{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-info">
                      <p className="mb-0"> 00%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total Applied Services
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {totalIncomplete + totalComplete}{" "}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total Rejected Services
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {dashboardInfo?.reject}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total Approved Services
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {dashboardInfo?.approved}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total Pending Services
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {dashboardInfo?.pending}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    {/* <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div> */}
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    {/* <p className="text-white-50 mb-0 mt-1">Since last month</p> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    Yearly/Monthly Service Details
                  </h4>
                  <BarApexChart
                    yearlyData={dashboardInfo?.yearlyData}
                    monthlyData={dashboardInfo?.monthlyData}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    Individual Service Details Chart
                  </h4>
                  <AreaChart data={dashboardInfo?.eachServiceDetails} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Line Chart</h4>
                  <LineApexChart />
                  <WeeklyChart/>
                </CardBody>
              </Card>
            </Col> */}
            <Col lg={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Service Chart</h4>
                  <DonutChart data={dashboardInfo?.eachServiceDetails} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
