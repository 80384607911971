import { COMPANY_COUNT, COMPANY_COUNT_API_ERROR } from "./actionTypes"

export const companyCount = companycount => {
  console.log("user count details here.", companycount)
  return {
    type: COMPANY_COUNT,
    payload: companycount,
  }
}

export const companyApiError = error => {
  return {
    type: COMPANY_COUNT_API_ERROR,
    payload: error,
  }
}
