import { PERMIT_COUNT, PERMIT_COUNT_API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  permitCount: 0,
}

const permitCount = (state = initialState, action) => {
  switch (action.type) {
    case PERMIT_COUNT:
      return {
        ...state,
        permitCount: action.payload,
      }
    case PERMIT_COUNT_API_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default permitCount
