import { call, put, takeEvery } from "redux-saga/effects"
import { vatApiError } from "./actions"
import { VAT_COUNT } from "./actionTypes"

function* VATCount({ payload }) {
  try {
  } catch (error) {
    yield put(vatApiError(error.message))
    if (error.message === "Token verification failed") {
      history.push("/login")
    }
  }
}

function* vatSaga() {
  yield takeEvery(VAT_COUNT, VATCount)
}

export default vatSaga
