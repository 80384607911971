import { call, put, takeEvery } from "redux-saga/effects"
import { companyApiError } from "./actions"
import { COMPANY_COUNT } from "./actionTypes"

function* COMPANYCount({ payload }) {
  try {
  } catch (error) {
    yield put(companyApiError(error.message))
    if (error.message === "Token verification failed") {
      history.push("/login")
    }
  }
}

function* companySaga() {
  yield takeEvery(COMPANY_COUNT, COMPANYCount)
}

export default companySaga
