import { useEffect, useState } from "react"
import { Button, Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")
import html2pdf from "html2pdf.js"


const KYCDetail = () => {
    const { KYCID } = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const apiURL = process.env.REACT_APP_BACKEND_URL

    document.title = "KYC Service Details | Admin & Dashboard"

    const fetchVisaDetails = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${apiURL}/api/admin/KYC/details/${KYCID}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const res = await response.json()
            console.log("goAmlID details", res)
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                console.log("res premium visa details", res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchVisaDetails()
    }, [])

    // Function to download PDF
    const downloadPDF = () => {
        const element = document.getElementById("pdf-content")
        const options = {
            filename: "KYC-Service-Details.pdf",
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        }
        html2pdf().from(element).set(options).save()
    }


    console.log("visa Data", data)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "right" }}
                    >
                        <Button className="p-2 d-flex gap-2" color="success"
              style={{ alignItems: "center" }}>
                            Download Form <i className="ti-download"></i>
                        </Button>
                    </div>
                    <div className="user-details">
                        <Row id="pdf-content">
                            <Col lg={12}>
                                <Card style={{ padding: "20px", marginTop: "20px" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>
                                        KYC Service Details
                                    </p>
                                    {data ? (
                                        <>
                                            <Row>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    {" "}
                                                    <strong> Entity Type : </strong>{" "}
                                                    <span style={{ textAlign: "end" }}>
                                                        {data?.entityType}{" "}
                                                    </span>
                                                </Col>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    {" "}
                                                    <strong>
                                                        Application Status By OnlineKorp :{" "}
                                                    </strong>{" "}
                                                    {data?.active}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong>Application Form Status :</strong>{" "}
                                                    {data?.status}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong> Applied Date : </strong>
                                                    {data?.createdAt?.substring(0, 10)}
                                                </Col>
                                                <Col
                                                    lg={12}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong> Provider Types : </strong>

                                                    {Array.isArray(data?.providerTypes)
                                                        ? data?.providerTypes
                                                            .map(type => {
                                                                if (type === "WATCHLIST") return "SCREENING"
                                                                if (type === "MEDIA_CHECK")
                                                                    return "MEDIA CHECK"
                                                                if (type === "PASSPORT")
                                                                    return "PASSPORT CHECK"
                                                                return type
                                                            })
                                                            .join(", ")
                                                        : ""}
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <div>
                                            <p
                                                colSpan="6"
                                                style={{ textAlign: "center", padding: "20px" }}
                                            >
                                                KYC Details Not Available.
                                            </p>
                                        </div>
                                    )}
                                </Card>
                                <Card style={{ padding: "20px" }}>
                                    {data ? (
                                        <>
                                            {data?.entityType === "INDIVIDUAL" ? (
                                              <Row>
                                              <p
                                                style={{
                                                  fontSize: "20px",
                                                  fontWeight: "500",
                                                  marginTop: "20px",
                                                }}
                                              >
                                                Individual Information
                                              </p>
                                              
                                              <Col lg={6} style={{ fontSize: "16px" }}>
                                                <strong>Name : </strong> {data?.name}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Name Transposition : </strong>
                                                {data?.nameTransposition ? "YES" : "NO"}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", }}>
                                                <strong>Gender : </strong> {data?.gender}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Place Of Birth : </strong>
                                                {data?.placeOfBirth}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "5px" }}>
                                                <strong>Date Of Birth : </strong>
                                                {data?.dateOfBirth}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Location : </strong>
                                                {data?.countryLocation}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Nationality : </strong>
                                                {data?.nationality}
                                              </Col>
                                              
                                              <hr  style={{marginTop:"20px"}}/>
                                              
                                              <Col
                                                lg={12}
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "500",
                                                  marginTop: "20px",
                                                }}
                                              >
                                                Passport Details
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Given Name : </strong>
                                                {data?.givenName}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Last Name : </strong>
                                                {data?.lastName}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Passport Number : </strong>
                                                {data?.identificationNumber}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Date Of Expiry : </strong>
                                                {data?.dateOfExpiry}
                                              </Col>
                                              
                                              <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                                                <strong>Issuing State : </strong>
                                                {data?.issuingState}
                                              </Col>
                                              <hr  style={{marginTop:"20px"}}/>
                                            </Row>
                                            
                                            
                                            ) : (
                                                data?.entityType === "ORGANISATION" && (
                                                    <Row>
                                                        <p
                                                            style={{
                                                                fontSize: "20px",
                                                                fontWeight: "500",
                                                                marginTop: "20px",
                                                            }}
                                                        >
                                                            Organisation Information
                                                        </p>
                                                        <Col
                                                            lg={6}
                                                            style={{
                                                                fontSize: "16px",
                                                            }}
                                                        >
                                                            <strong> Name : </strong> {data?.name}
                                                        </Col>
                                                        <hr  style={{marginTop:"20px"}}/>
                                                    </Row>
                                                )
                                            )}
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: "500",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    Package Information
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{
                                                        fontSize: "16px",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <strong> Package Type : </strong>{" "}
                                                    {data?.package?.packageType}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{
                                                        fontSize: "16px",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <strong> Package Price : </strong>{" "}
                                                    {data?.package?.packagePrice}
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <div>
                                            <p
                                                colSpan="6"
                                                style={{ textAlign: "center", padding: "20px" }}
                                            >
                                                Service Details Not Available.
                                            </p>
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KYCDetail
