import { KYC_COUNT, KYC_COUNT_API_ERROR } from "./actionTypes"

export const KYCCount = KYCcount => {
  console.log("user count details here.", KYCcount)
  return {
    type: KYC_COUNT,
    payload: KYCcount,
  }
}

export const KYCApiError = error => {
  return {
    type: KYC_COUNT_API_ERROR,
    payload: error,
  }
}
