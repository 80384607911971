import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import User from "./user/reducer"
import GoAML from "./goAml/reducer"
import Company from "./company/reducer"
import visa from "./visa/reducer"
import vat from "./vat/reducer"
import permit from "./workPermit/reducer"
import kyc from "./screening/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Profile,
  User,
  GoAML,
  Company,
  visa,
  vat,
  permit,
  kyc,
})

export default rootReducer
