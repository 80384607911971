import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Button, Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")
import html2pdf from "html2pdf.js"
import JSZip from "jszip"
import { saveAs } from "file-saver"

const VisaDetail = () => {
    const { visaID } = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true) // New loading state
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const apiURL = process.env.REACT_APP_BACKEND_URL

    document.title = "Service Details | Admin & Dashboard"

    const fetchVisaDetails = async () => {
        try {
            setLoading(true)
            const response = await fetch(
                `${apiURL}/api/admin/premium/details/${visaID}`,
                {
                    method: "GET", // GET is the default method, but it's good practice to specify it explicitly
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const res = await response.json()
            console.log("res premium visa details", res)
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                console.log("res premium visa details", res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchVisaDetails()
    }, [])

    const download = e => {
        e.preventDefault()

        const url = e.target.href
        console.log(url)

        fetch(url, {
            method: "GET",
            headers: {
                // You can add custom headers here if needed
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok")
                }
                return response.arrayBuffer()
            })
            .then(buffer => {
                const blob = new Blob([buffer])
                const link = document.createElement("a")
                const fileName = url.split("/").pop() // Extract file name from URL
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName // Use the extracted file name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // Clean up
                window.URL.revokeObjectURL(link.href) // Release the object URL
            })
            .catch(err => {
                console.error("Download failed:", err)
            })
    }

    // Function to download PDF
    const downloadPDF = () => {
        const element = document.getElementById("pdf-content")
        // Using html2pdf.js to generate and download the PDF
        const options = {
            filename: "Premium-Visa-Service-details.pdf",
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        }
        html2pdf().from(element).set(options).save()
        downloadAllDocuments()
    }

    const downloadAllDocuments = async () => {
        const zip = new JSZip();

        // Define the file names and labels for documents to download
        const filesToDownload = [
            { name: "photo", label: "Photo" },
            { name: "passport_Bio_Data_Page", label: "Passport Bio Data Page" },
            { name: "birth_Certificate", label: "Birth Certificate" },
            { name: "travelOrHealthInsurance", label: "Travel or Health Insurance" },
            { name: "airTicket", label: "Air Ticket" },
            { name: "consent_Letter", label: "Consent Letter" },
            { name: "spouse_Married_Certificate", label: "Spouse Married Certificate" },
            { name: "children_Birth_Certificate", label: "Children's Birth Certificate" },
            { name: "cv", label: "CV" },
            { name: "bankStatement", label: "Bank Statement" },
            { name: "monthly_Income_Proof", label: "Monthly Income Proof" },
            { name: "health_Insurance", label: "Health Insurance" },
            { name: "hotel_Rental_Invitation_Copy", label: "Hotel Rental Invitation Copy" },
            { name: "Copy_ID_Or_Passport_host_and_Utility_bill", label: "Copy ID/Passport and Utility Bill" },
            { name: "travel_Insurance", label: "Travel Insurance" }
        ];

        // Helper function to add files to the zip for each person (main, spouse, children)
        const addDocumentsToZip = async (person, folderName) => {
            const folder = zip.folder(folderName); // Create folder for the person

            for (let file of filesToDownload) {
                const fileUrl = person?.documents?.[file.name];
                if (fileUrl) {
                    const response = await fetch(fileUrl);
                    const contentType = response.headers.get("Content-Type");
                    let fileExtension = "pdf"; // Default file extension

                    if (contentType.includes("image")) {
                        if (contentType.includes("jpeg")) {
                            fileExtension = "jpg";
                        } else if (contentType.includes("png")) {
                            fileExtension = "png";
                        } else if (contentType.includes("gif")) {
                            fileExtension = "gif";
                        }
                    }

                    const blob = await response.blob();
                    folder.file(`${file.label}.${fileExtension}`, blob); // Add file inside the person's folder
                }
            }
        };

        // Add the main applicant documents
        if (data?.mainID?.documents) {
            await addDocumentsToZip(data.mainID, "Main Applicant");
        }

        // Add the spouse documents
        if (data?.spouseID?.documents) {
            await addDocumentsToZip(data.spouseID, "Spouse");
        }

        // Add the children's documents (iterate through the childID array)
        if (data?.childID && data.childID.length > 0) {
            for (let i = 0; i < data.childID.length; i++) {
                const child = data.childID[i];
                if (child?.documents) {
                    await addDocumentsToZip(child, `Child ${i + 1}`);
                }
            }
        }

        // Generate the zip and trigger download
        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, "Premium-Visa-documents.zip");
        });
    };

    console.log("visa details Data", data)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "right" }}
                    >
                        <Button className="p-2" color="success" onClick={downloadPDF}>
                            Download Form
                        </Button>
                    </div>
                    <div className="user-details">
                        <Row id="pdf-content">
                            <Col lg={12}>
                                <Card style={{ padding: "20px", marginTop: "20px" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>
                                        Premium Visa Service Details
                                    </p>
                                    {data ? (
                                        <>
                                            <Row
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "5px" }}
                                                >
                                                    {" "}
                                                    <strong> Jurisdiction : </strong>{" "}
                                                    <span style={{ textAlign: "end" }}>
                                                        {data?.jurisdiction}{" "}
                                                    </span>
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "5px" }}
                                                >
                                                    {" "}
                                                    <strong>
                                                        Application Status By OnlineKorp :{" "}
                                                    </strong>{" "}
                                                    {data?.active}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "5px" }}
                                                >
                                                    <strong>Application Form Status :</strong>{" "}
                                                    {data?.status}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "5px" }}
                                                >
                                                    <strong>Number Of Applicants :</strong>{" "}
                                                    {data?.noOfApplicants}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "5px" }}
                                                >
                                                    <strong>Applied Applicants :</strong>{" "}
                                                    {data?.noOfApplicantsRelation}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "5px" }}
                                                >
                                                    <strong> Applied Date : </strong>
                                                    {data?.createdAt?.substring(0, 10)}
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <div>
                                            <p
                                                colSpan="6"
                                                style={{ textAlign: "center", padding: "20px" }}
                                            >
                                                Applicant Details Not Available.
                                            </p>
                                        </div>
                                    )}
                                </Card>
                                {data?.mainID?._id && (
                                    <>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                <strong>Main Applicant Personal Details </strong>{" "}
                                            </p>
                                            <Row
                                                style={{
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                {data?.mainID && (
                                                    <>
                                                        <Col lg={12}>
                                                            <strong>Passport Details : </strong>
                                                        </Col>
                                                        <Col lg={6} style={{ marginTop: "10px" }}>
                                                            <strong>Application Type : </strong>{" "}
                                                            {data?.mainID?.applicationType}{" "}
                                                        </Col>
                                                        <Col lg={6} style={{ marginTop: "10px" }}>
                                                            <strong>Applied Country :</strong>{" "}
                                                            {data?.mainID?.appliedCountry}
                                                        </Col>
                                                        {data?.mainID?.applicationType === "renewal" && (
                                                            <>
                                                                <Col lg={6} style={{ marginTop: "8px" }}>
                                                                    <strong>Premium Visa No :</strong>{" "}
                                                                    {data?.mainID?.visaDetails?.visaNo}{" "}
                                                                </Col>
                                                                <Col lg={6} style={{ marginTop: "8px" }}>
                                                                    <strong>Validate Date :</strong>
                                                                    {data?.mainID?.visaDetails?.validateDate}{" "}
                                                                </Col>
                                                            </>
                                                        )}
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong> FullName : </strong>{" "}
                                                            {data?.mainID?.title} {data?.mainID?.firstName}{" "}
                                                            {data?.mainID?.middleName} {data?.mainID?.surName}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Gender : </strong>
                                                            {data?.mainID?.gender}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Marital Status : </strong>
                                                            {data?.mainID?.maritalStatus}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Date of Birth : </strong>
                                                            {data?.mainID?.dob}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Relation : </strong>
                                                            {data?.mainID?.relation}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Nationality : </strong>
                                                            {data?.mainID?.nationality}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Country of Birth : </strong>
                                                            {data?.mainID?.countryOfBirth}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Current Profession : </strong>
                                                            {data?.mainID?.currentProfession}
                                                        </Col>
                                                        {data?.mainID?.incomeDetails && (
                                                            <>
                                                                <strong style={{ marginTop: "20px" }}>
                                                                    Income Details :{" "}
                                                                </strong>{" "}
                                                                <Col
                                                                    lg={6}
                                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                                >
                                                                    <strong>Currency :</strong>{" "}
                                                                    {data?.mainID?.incomeDetails?.currency}
                                                                </Col>
                                                                <Col
                                                                    lg={6}
                                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                                >
                                                                    <strong>Average Monthly Income : </strong>{" "}
                                                                    {
                                                                        data?.mainID?.incomeDetails
                                                                            ?.AvgMonthlyIncome
                                                                    }
                                                                </Col>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Row>
                                        </Card>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                Main Applicant Contact Details
                                            </p>
                                            <Row>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Email : </strong>{" "}
                                                    {data?.mainID?.contactDetails?.email}
                                                </Col>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Emergency Contact : </strong>{" "}
                                                    {data?.mainID?.contactDetails?.emgContact}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> Mobile No : </strong>{" "}
                                                    {data?.mainID?.contactDetails?.mobileNo}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> TelePhone No : </strong>{" "}
                                                    {data?.mainID?.contactDetails?.phoneNumber}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> Permanent Address : </strong>{" "}
                                                    {data?.mainID?.contactDetails?.permanentAddress}
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                Main Applicant Stay Details
                                            </p>
                                            <Row>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Arrival Date : </strong>{" "}
                                                    {data?.mainID?.stayDetails?.arrivalDate?.substring(
                                                        0,
                                                        10
                                                    )}
                                                </Col>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Departure Date : </strong>{" "}
                                                    {data?.mainID?.stayDetails?.departureDate?.substring(
                                                        0,
                                                        10
                                                    )}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> Stay Purpose : </strong>{" "}
                                                    {data?.mainID?.stayDetails?.stayPurpose}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> Stay Details : </strong>{" "}
                                                    {data?.mainID?.stayDetails?.stayDetails}
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                Main Applicant Security Details
                                            </p>
                                            <Row>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <span>
                                                        1. Have you been convicted of a serious criminal
                                                        case :{" "}
                                                    </span>{" "}
                                                    {data?.mainID?.securityDetails?.criminalCases
                                                        ? "YES"
                                                        : "NO"}
                                                </Col>
                                                <Col
                                                    lg={12}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <span>
                                                        2. Is a criminal/civil case pending against you in
                                                        any country :{" "}
                                                    </span>
                                                    {data?.mainID?.securityDetails?.pendingCases
                                                        ? "YES"
                                                        : "NO"}
                                                </Col>
                                                <Col
                                                    lg={12}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <span>
                                                        {" "}
                                                        3. Are you suffering from any infections disease :{" "}
                                                    </span>
                                                    {data?.mainID?.securityDetails?.infection
                                                        ? "YES"
                                                        : "NO"}
                                                </Col>
                                            </Row>
                                        </Card>
                                        <div style={{ marginTop: "10px" }}>
                                            <Card style={{ padding: "20px", fontSize: "18px" }}>
                                                <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                    Main Applicant Documents
                                                </p>
                                                <Row style={{ fontSize: "16px" }}>
                                                    <Col lg={10}>
                                                        <div>
                                                            {data?.mainID?.documents?.photo && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Passport Size Photo</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={data?.mainID?.documents?.photo}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={data?.mainID?.documents?.photo}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.mainID?.documents
                                                                ?.passport_Bio_Data_Page && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>Bio Data Page Of Passport</strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.passport_Bio_Data_Page
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.passport_Bio_Data_Page
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.mainID?.documents?.cv && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>CV</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={data?.mainID?.documents?.cv}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={data?.mainID?.documents?.cv}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.mainID?.documents?.airline_Ticket && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Copy Of Airline Ticket</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents?.airline_Ticket
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents?.airline_Ticket
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.mainID?.documents?.travel_Insurance && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>
                                                                                Travel Insurance For Intended Period Of
                                                                                Stay
                                                                            </strong>
                                                                            :{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents
                                                                                    ?.travel_Insurance
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents
                                                                                    ?.travel_Insurance
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.mainID?.documents?.health_Insurance && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>
                                                                                Health Insurance For Intended Period Of
                                                                                Stay
                                                                            </strong>
                                                                            :{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents
                                                                                    ?.health_Insurance
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents
                                                                                    ?.health_Insurance
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.mainID?.documents
                                                                ?.hotel_Rental_Invitation_Copy && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>
                                                                                    Hotel Booking/Rental Agreement/Letter Of
                                                                                    Invitation From Host
                                                                                </strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.hotel_Rental_Invitation_Copy
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.hotel_Rental_Invitation_Copy
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.mainID?.documents
                                                                ?.Copy_ID_Or_Passport_host_and_Utility_bill && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>
                                                                                    For applicant accommodated by host,
                                                                                    please submit a Copy of ID or Passport
                                                                                    of host and Utility bill Of Host
                                                                                </strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.Copy_ID_Or_Passport_host_and_Utility_bill
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.Copy_ID_Or_Passport_host_and_Utility_bill
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.mainID?.documents?.bankStatement && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>
                                                                                Recent Bank Statements (last three (3)
                                                                                months)
                                                                            </strong>
                                                                            :{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents?.bankStatement
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.mainID?.documents?.bankStatement
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.mainID?.documents
                                                                ?.monthly_Income_Proof && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>
                                                                                    Proof Of Monthly Income (employment
                                                                                    contract or attestation for monthly
                                                                                    revenue)
                                                                                </strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.monthly_Income_Proof
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.monthly_Income_Proof
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.mainID?.documents
                                                                ?.spouse_Married_Certificate && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>
                                                                                    Spouse Married Certificate (in
                                                                                    English/French Language)
                                                                                </strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.spouse_Married_Certificate
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.mainID?.documents
                                                                                        ?.spouse_Married_Certificate
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    </>
                                )}
                                {data?.spouseID?._id && (
                                    <>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                <strong>Spouse Personal Details </strong>{" "}
                                            </p>
                                            <Row>
                                                {data?.spouseID && (
                                                    <>
                                                        <Col lg={12} style={{ fontSize: "16px" }}>
                                                            <strong>Passport Details : </strong>
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong> Application Type : </strong>{" "}
                                                            {data?.spouseID?.applicationType}{" "}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong> Applied Country : </strong>{" "}
                                                            {data?.spouseID?.appliedCountry}
                                                        </Col>

                                                        {data?.spouseID?.applicationType === "renewal" && (
                                                            <>
                                                                <Col
                                                                    lg={6}
                                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                                >
                                                                    <strong> Premium Visa No : </strong>{" "}
                                                                    {data?.spouseID?.visaDetails?.visaNo}{" "}
                                                                </Col>
                                                                <Col
                                                                    lg={6}
                                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                                >
                                                                    <strong> Validate Date : </strong>{" "}
                                                                    {data?.spouseID?.visaDetails?.validateDate}{" "}
                                                                </Col>
                                                            </>
                                                        )}

                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong> FullName : </strong>{" "}
                                                            {data?.spouseID?.title}{" "}
                                                            {data?.spouseID?.firstName}{" "}
                                                            {data?.spouseID?.middleName}{" "}
                                                            {data?.spouseID?.surName}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Gender : </strong>
                                                            {data?.spouseID?.gender}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Marital Status : </strong>
                                                            {data?.spouseID?.maritalStatus}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Date of Birth : </strong>
                                                            {data?.spouseID?.dob}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Relation : </strong>
                                                            {data?.spouseID?.relation}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Nationality : </strong>
                                                            {data?.spouseID?.nationality}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Country of Birth : </strong>
                                                            {data?.spouseID?.countryOfBirth}
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            style={{ fontSize: "16px", marginTop: "8px" }}
                                                        >
                                                            <strong>Current Profession : </strong>
                                                            {data?.spouseID?.currentProfession}
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </Card>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                Spouse Contact Details
                                            </p>
                                            <Row>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Email : </strong>{" "}
                                                    {data?.spouseID?.contactDetails?.email}
                                                </Col>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Emergency Contact : </strong>{" "}
                                                    {data?.spouseID?.contactDetails?.emgContact}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> Mobile No : </strong>{" "}
                                                    {data?.spouseID?.contactDetails?.mobileNo}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> TelePhone No : </strong>{" "}
                                                    {data?.spouseID?.contactDetails?.phoneNumber}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <strong> Permanent Address : </strong>{" "}
                                                    {data?.spouseID?.contactDetails?.permanentAddress}
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                Spouse Applicant Stay Details
                                            </p>
                                            <Row>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Arrival Date : </strong>{" "}
                                                    {data?.spouseID?.stayDetails?.arrivalDate?.substring(
                                                        0,
                                                        10
                                                    )}
                                                </Col>
                                                <Col lg={6} style={{ fontSize: "16px" }}>
                                                    <strong> Departure Date : </strong>{" "}
                                                    {data?.spouseID?.stayDetails?.departureDate?.substring(
                                                        0,
                                                        10
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                Spouse Security Details
                                            </p>
                                            <Row>
                                                <Col lg={12} style={{ fontSize: "16px" }}>
                                                    <span>
                                                        1. Have you been convicted of a serious criminal
                                                        case :{" "}
                                                    </span>{" "}
                                                    {data?.spouseID?.securityDetails?.criminalCases
                                                        ? "YES"
                                                        : "NO"}
                                                </Col>
                                                <Col
                                                    lg={12}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <span>
                                                        2. Is a criminal/civil case pending against you in
                                                        any country :{" "}
                                                    </span>
                                                    {data?.spouseID?.securityDetails?.pendingCases
                                                        ? "YES"
                                                        : "NO"}
                                                </Col>
                                                <Col
                                                    lg={12}
                                                    style={{ fontSize: "16px", marginTop: "8px" }}
                                                >
                                                    <span>
                                                        {" "}
                                                        3. Are you suffering from any infections disease :{" "}
                                                    </span>
                                                    {data?.spouseID?.securityDetails?.infection
                                                        ? "YES"
                                                        : "NO"}
                                                </Col>
                                            </Row>
                                        </Card>
                                        <div style={{ marginTop: "10px" }}>
                                            <Card style={{ padding: "20px" }}>
                                                <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                    Spouse Applicant Documents
                                                </p>
                                                <Row style={{ fontSize: "16px" }}>
                                                    <Col lg={12}>
                                                        <div>
                                                            {data?.spouseID?.documents?.photo && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Passport Size Photo</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={data?.spouseID?.documents?.photo}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={data?.spouseID?.documents?.photo}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.spouseID?.documents
                                                                ?.passport_Bio_Data_Page && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>Bio Data Page Of Passport</strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.spouseID?.documents
                                                                                        ?.passport_Bio_Data_Page
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.spouseID?.documents
                                                                                        ?.passport_Bio_Data_Page
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.spouseID?.documents?.airTicket && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Copy Of Airline Ticket</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.spouseID?.documents?.airTicket
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.spouseID?.documents?.airTicket
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.spouseID?.documents
                                                                ?.travelOrHealthInsurance && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>
                                                                                    Travel Insurance For Intended Period Of
                                                                                    Stay :
                                                                                </strong>{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.spouseID?.documents
                                                                                        ?.travelOrHealthInsurance
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.spouseID?.documents
                                                                                        ?.travelOrHealthInsurance
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}

                                                            {data?.spouseID?.documents
                                                                ?.spouse_Married_Certificate && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                <strong>
                                                                                    Spouse Married Certificate (in
                                                                                    English/French Language)
                                                                                </strong>
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.spouseID?.documents
                                                                                        ?.spouse_Married_Certificate
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.spouseID?.documents
                                                                                        ?.spouse_Married_Certificate
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    </>
                                )}

                                {data?.childID?.length > 0 &&
                                    data?.childID?.map((el, i) => (
                                        <>
                                            <Card style={{ padding: "20px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                    <strong>
                                                        {i == 0 ? "First" : i == 1 ? "Second" : "Third"}{" "}
                                                        Child Details :{" "}
                                                    </strong>{" "}
                                                </p>
                                                <Row>
                                                    <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                        <strong>Passport Details : </strong>{" "}
                                                    </p>

                                                    <Col lg={6} style={{ fontSize: "16px" }}>
                                                        <strong> Application Type : </strong>{" "}
                                                        {el?.applicationType}{" "}
                                                    </Col>
                                                    <Col lg={6} style={{ fontSize: "16px" }}>
                                                        <strong> Applied Country : </strong>{" "}
                                                        {el?.appliedCountry}
                                                    </Col>

                                                    {el?.applicationType === "renewal" && (
                                                        <>
                                                            <Col
                                                                lg={6}
                                                                style={{ fontSize: "16px", marginTop: "8px" }}
                                                            >
                                                                <strong> Premium Visa No : </strong>{" "}
                                                                {el?.visaDetails?.visaNo}{" "}
                                                            </Col>
                                                            <Col
                                                                lg={6}
                                                                style={{ fontSize: "16px", marginTop: "8px" }}
                                                            >
                                                                <strong> Validate Date : </strong>{" "}
                                                                {el?.visaDetails?.validateDate}{" "}
                                                            </Col>
                                                        </>
                                                    )}

                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong> FullName : </strong> {el?.title}{" "}
                                                        {el?.firstName} {el?.middleName} {el?.surName}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Gender : </strong>
                                                        {el?.gender}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Marital Status : </strong>
                                                        {el?.maritalStatus}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Date of Birth : </strong>
                                                        {el?.dob}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Relation : </strong>
                                                        {el?.relation}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Nationality : </strong>
                                                        {el?.nationality}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Country of Birth : </strong>
                                                        {el?.countryOfBirth}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong>Current Profession : </strong>
                                                        {el?.currentProfession}
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card style={{ padding: "20px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                    Contact Details
                                                </p>
                                                <Row>
                                                    <Col lg={6} style={{ fontSize: "16px" }}>
                                                        <strong> Email : </strong>{" "}
                                                        {el?.contactDetails?.email}
                                                    </Col>
                                                    <Col lg={6} style={{ fontSize: "16px" }}>
                                                        <strong> Emergency Contact : </strong>{" "}
                                                        {el?.contactDetails?.emgContact}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong> Mobile No : </strong>{" "}
                                                        {el?.contactDetails?.mobileNo}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong> TelePhone No : </strong>{" "}
                                                        {el?.contactDetails?.phoneNumber}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "8px" }}
                                                    >
                                                        <strong> Permanent Address : </strong>{" "}
                                                        {el?.contactDetails?.permanentAddress}
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card style={{ padding: "20px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                    Stay Details
                                                </p>
                                                <Row>
                                                    <Col lg={6} style={{ fontSize: "16px" }}>
                                                        <strong> Arrival Date : </strong>{" "}
                                                        {el?.stayDetails?.arrivalDate?.substring(0, 10)}
                                                    </Col>
                                                    <Col lg={6} style={{ fontSize: "16px" }}>
                                                        <strong> Departure Date : </strong>{" "}
                                                        {el?.stayDetails?.departureDate?.substring(0, 10)}
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card style={{ padding: "20px" }}>
                                                <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                    Security Details
                                                </p>
                                                <Row>
                                                    <Col lg={12} style={{ fontSize: "16px" }}>
                                                        <span>
                                                            1. Have you been convicted of a serious criminal
                                                            case :{" "}
                                                        </span>{" "}
                                                        {el?.securityDetails?.criminalCases ? "YES" : "NO"}
                                                    </Col>
                                                    <Col
                                                        lg={12}
                                                        style={{ fontSize: "16px", marginTop: "5px" }}
                                                    >
                                                        <span>
                                                            2. Is a criminal/civil case pending against you in
                                                            any country :{" "}
                                                        </span>
                                                        {el?.securityDetails?.pendingCases ? "YES" : "NO"}
                                                    </Col>
                                                    <Col
                                                        lg={12}
                                                        style={{ fontSize: "16px", marginTop: "5px" }}
                                                    >
                                                        <span>
                                                            {" "}
                                                            3. Are you suffering from any infections disease :{" "}
                                                        </span>
                                                        {el?.securityDetails?.infection ? "YES" : "NO"}
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <div style={{ marginTop: "10px" }}>
                                                <Card style={{ padding: "20px" }}>
                                                    <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                        Documents
                                                    </p>

                                                    <Col lg={10} style={{ marginTop: "5px" }}>
                                                        <div>
                                                            {el?.documents?.photo && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Passport Size Photo</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.photo}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.photo}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {el?.documents?.passport_Bio_Data_Page && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Bio Data Page Of Passport</strong>
                                                                            :{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                el?.documents?.passport_Bio_Data_Page
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                el?.documents?.passport_Bio_Data_Page
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {el?.documents?.airTicket && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Copy Of Airline Ticket</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.airTicket}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.airTicket}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {el?.documents?.travelOrHealthInsurance && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>
                                                                                Travel Insurance For Intended Period Of
                                                                                Stay
                                                                            </strong>
                                                                            :{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                el?.documents?.travelOrHealthInsurance
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                el?.documents?.travelOrHealthInsurance
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}

                                                            {el?.documents?.birth_Certificate && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Birth Certificate</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.birth_Certificate}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.birth_Certificate}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {el?.documents?.consent_Letter && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Consent Letter</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.consent_Letter}
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={el?.documents?.consent_Letter}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Card>
                                            </div>
                                        </>
                                    ))}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisaDetail
