import { VAT_COUNT, VAT_COUNT_API_ERROR } from "./actionTypes"

export const vatCount = vatcount => {
  console.log("user count details here.", vatcount)
  return {
    type: VAT_COUNT,
    payload: vatcount,
  }
}

export const vatApiError = error => {
  return {
    type: VAT_COUNT_API_ERROR,
    payload: error,
  }
}
