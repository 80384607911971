import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")

const AgreementDetail = () => {
  const { agreementID } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL
  document.title = "Service Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `${apiURL}/api/admin/agreement/details/${agreementID}`,
        {
          method: "GET", // GET is the default method, but it's good practice to specify it explicitly
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      console.log("res premium visa details", res)
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        console.log("res premium visa details", res.data)
        setData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false) // Set loading to false after fetching
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card style={{ padding: "20px", marginTop: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  Agreements & Contract Service Details
                </p>
                {loading ? (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#6f42c1"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                ) : data ? (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        {" "}
                        <strong> Jurisdiction : </strong>{" "}
                        <span style={{ textAlign: "end" }}>
                          {data?.jurisdiction}{" "}
                        </span>
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        {" "}
                        <strong>Agreement & Contract Name : </strong>{" "}
                        {data?.templateName}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Application Form Status :</strong>{" "}
                        {data?.status}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Payment Status :</strong>{" "}
                        {data?.isPayment ? "Success" : "Failed"}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Agreement & Contract Price :</strong>{" "}
                        {data?.packagePrice ? `$${data?.packagePrice}` : 0}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> Applied Date : </strong>
                        {data?.createdAt?.substring(0, 10)}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div>
                    <p
                      colSpan="6"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      Service Details Not Available.
                    </p>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AgreementDetail
