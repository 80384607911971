import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Button, Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")
import html2pdf from "html2pdf.js"
import JSZip from "jszip"
import { saveAs } from "file-saver"

const CompanyDetail = () => {
    const { companyID } = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true) // New loading state
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const apiURL = process.env.REACT_APP_BACKEND_URL

    document.title = "Service Details | Admin & Dashboard"

    const fetchVisaDetails = async () => {
        try {
            setLoading(true)
            const response = await fetch(
                `${apiURL}/api/admin/domestic/details/${companyID}`,
                {
                    method: "GET", // GET is the default method, but it's good practice to specify it explicitly
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const res = await response.json()
            console.log("res premium visa details", res)
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                console.log("res premium visa details", res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    useEffect(() => {
        fetchVisaDetails()
    }, [])

    const download = e => {
        e.preventDefault()

        const url = e.target.href
        console.log(url)

        fetch(url, {
            method: "GET",
            headers: {
                // You can add custom headers here if needed
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok")
                }
                return response.arrayBuffer()
            })
            .then(buffer => {
                const blob = new Blob([buffer])
                const link = document.createElement("a")
                const fileName = url.split("/").pop() // Extract file name from URL
                link.href = window.URL.createObjectURL(blob)
                link.download = fileName // Use the extracted file name
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // Clean up
                window.URL.revokeObjectURL(link.href) // Release the object URL
            })
            .catch(err => {
                console.error("Download failed:", err)
            })
    }

    // Function to download PDF
    const downloadPDF = () => {
        const element = document.getElementById("pdf-content")
        // Using html2pdf.js to generate and download the PDF
        const options = {
            filename: "Incorporate-Company-Service-details.pdf",
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        }
        html2pdf().from(element).set(options).save();
        downloadAllDocuments();
    }

    const downloadAllDocuments = async () => {
        const zip = new JSZip();

        // Define the files to download from the response object
        const documentsToDownload = [
            // Secretary documents
            {
                folder: "Secretary",
                files: [
                    { name: "secretary_consentForm", label: "Secretary Consent Form" },
                    { name: "passport", label: "Secretary Passport" },
                    { name: "proofOfAddress", label: "Secretary Proof of Address" },
                    { name: "qualifications", label: "Secretary Qualifications" }
                ]
            },
            // Register office documents (Utility Bill is specifically in this category)
            {
                folder: "RegisterOffice",
                files: [
                    { name: "utility_bill_document", label: "Register Office Utility Bill" }
                ]
            },
            // Shareholder documents (handle multiple shareholders)
            {
                folder: "Shareholders",
                files: {
                    corporate: [
                        { name: "consentForm", label: "Corporate Shareholder Consent Form" },
                        { name: "incorporation_Certificate", label: "Corporate Shareholder Incorporation Certificate" },
                        { name: "evidence_Annual_Registration_Payment", label: "Corporate Shareholder Evidence Annual Registration Payment" },
                        { name: "register_Directors", label: "Corporate Shareholder Register Directors" },
                        { name: "constitution", label: "Corporate Shareholder Constitution" },
                        { name: "register_Shareholders", label: "Corporate Shareholder Register Shareholders" },
                        { name: "kyc_Individual_Register_Directors", label: "Corporate Shareholder KYC Individual Register Directors" },
                        { name: "kyc_UBOs", label: "Corporate Shareholder KYC UBOs" },
                        { name: "proof_Source_Funds", label: "Corporate Shareholder Proof Source Funds" }
                    ],
                    individual: [
                        { name: "consentForm", label: "Individual Shareholder Consent Form" },
                        { name: "passportOrNIC", label: "Individual Shareholder Passport or NIC" },
                        { name: "proofOfAddress", label: "Individual Shareholder Proof of Address" },
                        { name: "bankStatementOrSalarySlip", label: "Individual Shareholder Bank Statement or Salary Slip" },
                        { name: "cv", label: "Individual Shareholder CV" }
                    ]
                }
            },
            // Director documents (handle multiple directors)
            {
                folder: "Directors",
                files: [
                    { name: "consentForm", label: "Director Consent Form" },
                    { name: "passportOrNIC", label: "Director Passport or NIC" },
                    { name: "proofOfAddress", label: "Director Proof of Address" },
                    { name: "cv", label: "Director CV" }
                ]
            }
        ];

        // Iterate through each section (e.g., Secretary, Shareholders) and add their documents to the zip
        for (const section of documentsToDownload) {
            const folder = zip.folder(section.folder);  // Create a folder for the section

            // Handle Secretary documents
            if (section.folder === "Secretary") {
                for (let file of section.files) {
                    const fileUrl = data?.secretary_Details?.documents?.[file.name];
                    if (fileUrl) {
                        try {
                            const response = await fetch(fileUrl);
                            const contentType = response.headers.get("Content-Type");
                            let fileExtension = "pdf"; // Default to pdf

                            // Determine the file extension based on Content-Type
                            if (contentType.includes("image")) {
                                if (contentType.includes("jpeg")) fileExtension = "jpg";
                                else if (contentType.includes("png")) fileExtension = "png";
                            }

                            const blob = await response.blob();
                            folder.file(`${file.label}.${fileExtension}`, blob);  // Add the file with correct extension
                        } catch (error) {
                            console.error(`Failed to fetch ${file.name} for Secretary: ${error}`);
                        }
                    }
                }
            }
            // Handle Shareholders documents (multiple shareholders)
            else if (section.folder === "Shareholders") {
                for (let i = 0; i < data.shareholders.length; i++) {
                    const shareholder = data.shareholders[i];
                    const shareholderFolder = folder.folder(`Shareholder-${i + 1}`);  // Create subfolder for each shareholder

                    const shareholderType = shareholder?.shareholderType;
                    if (!shareholderType) {
                        console.error(`Shareholder ${i + 1} has no type defined!`);
                        continue; // Skip this shareholder if no type is provided
                    }

                    const shareholderDocuments = shareholderType === "Corporate" ? section.files.corporate : section.files.individual;

                    console.log(`Downloading documents for Shareholder ${i + 1}, Type: ${shareholderType}`);

                    for (let file of shareholderDocuments) {
                        const fileUrl = shareholder?.documents?.[file.name];
                        if (fileUrl) {
                            try {
                                const response = await fetch(fileUrl);
                                const contentType = response.headers.get("Content-Type");
                                let fileExtension = "pdf"; // Default to pdf

                                // Determine the file extension based on Content-Type
                                if (contentType.includes("image")) {
                                    if (contentType.includes("jpeg")) fileExtension = "jpg";
                                    else if (contentType.includes("png")) fileExtension = "png";
                                }

                                const blob = await response.blob();
                                shareholderFolder.file(`${file.label}.${fileExtension}`, blob);  // Add to shareholder subfolder
                            } catch (error) {
                                console.error(`Failed to fetch ${file.name} for Shareholder ${i + 1}: ${error}`);
                            }
                        } else {
                            console.warn(`Document ${file.name} not found for Shareholder ${i + 1}`);
                        }
                    }
                }
            }
            // Handle Directors documents (multiple directors)
            else if (section.folder === "Directors") {
                for (let i = 0; i < data.directors.length; i++) {
                    const director = data.directors[i];
                    const directorFolder = folder.folder(`Director-${i + 1}`);  // Create subfolder for each director

                    for (let file of section.files) {
                        const fileUrl = director.documents?.[file.name];
                        if (fileUrl) {
                            try {
                                const response = await fetch(fileUrl);
                                const contentType = response.headers.get("Content-Type");
                                let fileExtension = "pdf"; // Default to pdf

                                // Determine the file extension based on Content-Type
                                if (contentType.includes("image")) {
                                    if (contentType.includes("jpeg")) fileExtension = "jpg";
                                    else if (contentType.includes("png")) fileExtension = "png";
                                }

                                const blob = await response.blob();
                                directorFolder.file(`${file.label}.${fileExtension}`, blob);  // Add to director subfolder
                            } catch (error) {
                                console.error(`Failed to fetch ${file.name} for Director ${i + 1}: ${error}`);
                            }
                        }
                    }
                }
            } else {
                // Handle other sections (e.g., RegisterOffice)
                for (let file of section.files) {
                    const fileUrl = data?.[section.folder.toLowerCase()]?.documents?.[file.name];
                    if (fileUrl) {
                        try {
                            const response = await fetch(fileUrl);
                            const contentType = response.headers.get("Content-Type");
                            let fileExtension = "pdf"; // Default to pdf

                            // Determine the file extension based on Content-Type
                            if (contentType.includes("image")) {
                                if (contentType.includes("jpeg")) fileExtension = "jpg";
                                else if (contentType.includes("png")) fileExtension = "png";
                            }

                            const blob = await response.blob();
                            folder.file(`${file.label}.${fileExtension}`, blob);  // Add to the respective folder
                        } catch (error) {
                            console.error(`Failed to fetch ${file.name} for ${section.folder}: ${error}`);
                        }
                    }
                }
            }
        }

        // Handle the utility bill document separately since it is not part of the "documents" section
        const utilityBillUrl = data?.registerOfficeDetails?.utility_bill_document;
        if (utilityBillUrl) {
            try {
                const response = await fetch(utilityBillUrl);
                const contentType = response.headers.get("Content-Type");
                let fileExtension = "pdf"; // Default to pdf

                if (contentType.includes("image")) {
                    if (contentType.includes("jpeg")) fileExtension = "jpg";
                    else if (contentType.includes("png")) fileExtension = "png";
                }

                const blob = await response.blob();
                zip.folder("RegisterOffice").file("Utility Bill." + fileExtension, blob);  // Add to RegisterOffice folder
            } catch (error) {
                console.error(`Failed to fetch utility bill: ${error}`);
            }
        }

        // Generate the zip file and initiate the download
        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, "Incorporate-Company-documents.zip");
        });
    };


    console.log("company details Data", data)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "right" }}
                    >
                        <Button
                            className="p-2"
                            color="success"
                            onClick={downloadPDF}
                        >
                            Download Form
                        </Button>
                    </div>
                    <div className="user-details">
                        <Row id="pdf-content">
                            <Col lg={12}>
                                <Card style={{ padding: "20px", marginTop: "20px" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>
                                        Incorporate Domestic Company Service Details
                                    </p>
                                    {data ? (
                                        <>
                                            <Row
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    {" "}
                                                    <strong> Jurisdiction : </strong>{" "}
                                                    <span style={{ textAlign: "end" }}>
                                                        {data?.jurisdiction}{" "}
                                                    </span>
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    {" "}
                                                    <strong>
                                                        Application Status By OnlineKorp :{" "}
                                                    </strong>{" "}
                                                    {data?.active}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong>Application Form Status :</strong>{" "}
                                                    {data?.status}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong>Number Of Shareholders :</strong>{" "}
                                                    {data?.shareholder_Number
                                                        ? data?.shareholder_Number
                                                        : 0}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong>Number Of Directors :</strong>{" "}
                                                    {data?.director_Number ? data?.director_Number : 0}
                                                </Col>
                                                <Col
                                                    lg={6}
                                                    style={{ fontSize: "16px", marginTop: "10px" }}
                                                >
                                                    <strong> Applied Date : </strong>
                                                    {data?.createdAt?.substring(0, 10)}
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <div>
                                            <p
                                                colSpan="6"
                                                style={{ textAlign: "center", padding: "20px" }}
                                            >
                                                Applicant Details Not Available.
                                            </p>
                                        </div>
                                    )}
                                </Card>
                                <Card style={{ padding: "20px" }}>
                                    <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                        Company Business Details
                                    </p>
                                    {data?.company_Business_Details ? (
                                        <Row
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Col
                                                lg={12}
                                                style={{
                                                    fontSize: "16px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <strong> Company Name Options : </strong>{" "}
                                                <ul style={{ textAlign: "start" }}>
                                                    <li>
                                                        {data?.company_Business_Details?.companyNames[0]}{" "}
                                                    </li>
                                                    <li>
                                                        {data?.company_Business_Details?.companyNames[1]}{" "}
                                                    </li>
                                                    <li>
                                                        {data?.company_Business_Details?.companyNames[2]}{" "}
                                                    </li>
                                                    <li>
                                                        {data?.company_Business_Details?.companyNames[3]}{" "}
                                                    </li>
                                                    <li>
                                                        {data?.company_Business_Details?.companyNames[4]}{" "}
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col lg={6} style={{ fontSize: "16px" }}>
                                                <strong>Company Business Activity : </strong>{" "}
                                                {data?.company_Business_Details?.business_Activity}
                                            </Col>
                                            <Col
                                                lg={12}
                                                style={{ fontSize: "16px", marginTop: "5px" }}
                                            >
                                                <strong>
                                                    Company Business Activity Descriptions :{" "}
                                                </strong>
                                                {
                                                    data?.company_Business_Details
                                                        ?.business_Activity_Details
                                                }
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div>
                                            <p
                                                colSpan="6"
                                                style={{ textAlign: "center", padding: "20px" }}
                                            >
                                                Applicant Details Not Available.
                                            </p>
                                        </div>
                                    )}
                                </Card>
                                <Card style={{ padding: "20px" }}>
                                    <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                        Company VAT Details
                                    </p>
                                    {data?.company_VatDetails ? (
                                        <Row
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Col lg={12} style={{ fontSize: "16px" }}>
                                                <strong> Company TurnOver : </strong>{" "}
                                                {data?.company_VatDetails?.turnOver}
                                            </Col>
                                            <Col
                                                lg={12}
                                                style={{ fontSize: "16px", marginTop: "5px" }}
                                            >
                                                <strong>
                                                    Company Business Activity Descriptions :{" "}
                                                </strong>
                                                {data?.company_VatDetails?.vatCategory}
                                            </Col>
                                            {data?.company_VatDetails?.vatCategory ===
                                                "compulsory_vat" && (
                                                    <Col
                                                        lg={6}
                                                        style={{ fontSize: "16px", marginTop: "5px" }}
                                                    >
                                                        <strong>Company Business : </strong>{" "}
                                                        {data?.company_VatDetails?.selectedBusiness}
                                                    </Col>
                                                )}
                                        </Row>
                                    ) : (
                                        <div>
                                            <p
                                                colSpan="6"
                                                style={{ textAlign: "center", padding: "20px" }}
                                            >
                                                Applicant Details Not Available.
                                            </p>
                                        </div>
                                    )}
                                </Card>
                                <div style={{ marginTop: "10px" }}>
                                    <Card>
                                        <div style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "18px", fontWeight: "500" }}>
                                                Shareholders Details
                                            </p>
                                            {loading ? (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "20px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <ThreeDots
                                                        visible={true}
                                                        height="80"
                                                        width="80"
                                                        color="#6f42c1"
                                                        radius="9"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                            ) : (
                                                <Row>
                                                    {data && data?.shareholders?.length > 0 ? (
                                                        data?.shareholders?.map((el, i) => (
                                                            <>
                                                                <h5 style={{ marginTop: "15px" }}>
                                                                    {i + 1 == 1
                                                                        ? "First"
                                                                        : i + 1 == 2
                                                                            ? "Second"
                                                                            : i + 1 == 3
                                                                                ? "Third"
                                                                                : i + 1 == 4
                                                                                    ? "Fourth"
                                                                                    : "Fifth"}{" "}
                                                                    Shareholder :
                                                                </h5>
                                                                <Col lg={12} style={{ marginTop: "10px" }}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Col lg={6}>
                                                                            <p>
                                                                                <strong>Shareholder Type</strong> :
                                                                                <span>{el?.shareholderType} </span>
                                                                            </p>
                                                                            <p>
                                                                                <strong>Number Of Share</strong> :{" "}
                                                                                {el?.shareNumber}
                                                                            </p>
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <p>
                                                                                <strong>Share Type</strong> :{" "}
                                                                                {el?.shareType}
                                                                            </p>
                                                                            <p>
                                                                                <strong>Values Of share</strong> :{" "}
                                                                                {el?.shareValues}
                                                                            </p>
                                                                        </Col>
                                                                    </div>

                                                                    <div>
                                                                        <h5>Documents</h5>
                                                                        <Col lg={10} style={{ marginTop: "15px" }}>
                                                                            <div>
                                                                                {el?.documents?.consentForm && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                <strong> Consent Form</strong>:{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.consentForm
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.consentForm
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents?.passportOrNIC && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    {" "}
                                                                                                    Passport/NIC{" "}
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.passportOrNIC
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents?.passportOrNIC
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents?.proofOfAddress && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    {" "}
                                                                                                    Proof of Address (less than 3
                                                                                                    months){" "}
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.proofOfAddress
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents?.proofOfAddress
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents
                                                                                    ?.bankStatementOrSalarySlip && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <p>
                                                                                                    {" "}
                                                                                                    <strong>
                                                                                                        {" "}
                                                                                                        Bank Statement/Salary Slip{" "}
                                                                                                    </strong>{" "}
                                                                                                    :{" "}
                                                                                                </p>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el?.documents
                                                                                                            ?.bankStatementOrSalarySlip
                                                                                                    }
                                                                                                    download
                                                                                                    onClick={e => download(e)}
                                                                                                >
                                                                                                    Download
                                                                                                </a>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el.documents
                                                                                                            ?.bankStatementOrSalarySlip
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                >
                                                                                                    View Document
                                                                                                </a>
                                                                                            </Col>
                                                                                        </div>
                                                                                    )}
                                                                                {el?.documents?.cv && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong> CV</strong> :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el?.documents?.cv}
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el.documents?.cv}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents
                                                                                    ?.incorporation_Certificate && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <p>
                                                                                                    {" "}
                                                                                                    <strong>
                                                                                                        {" "}
                                                                                                        Certificate of incorporation
                                                                                                    </strong>{" "}
                                                                                                    :{" "}
                                                                                                </p>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el?.documents
                                                                                                            ?.incorporation_Certificate
                                                                                                    }
                                                                                                    download
                                                                                                    onClick={e => download(e)}
                                                                                                >
                                                                                                    Download
                                                                                                </a>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el.documents
                                                                                                            ?.incorporation_Certificate
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                >
                                                                                                    View Document
                                                                                                </a>
                                                                                            </Col>
                                                                                        </div>
                                                                                    )}
                                                                                {el?.documents
                                                                                    ?.evidence_Annual_Registration_Payment && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <p>
                                                                                                    {" "}
                                                                                                    <strong>
                                                                                                        {" "}
                                                                                                        Evidence of last annual
                                                                                                        registration payment{" "}
                                                                                                    </strong>{" "}
                                                                                                    :{" "}
                                                                                                </p>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el?.documents
                                                                                                            ?.evidence_Annual_Registration_Payment
                                                                                                    }
                                                                                                    download
                                                                                                    onClick={e => download(e)}
                                                                                                >
                                                                                                    Download
                                                                                                </a>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el.documents
                                                                                                            ?.evidence_Annual_Registration_Payment
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                >
                                                                                                    View Document
                                                                                                </a>
                                                                                            </Col>
                                                                                        </div>
                                                                                    )}
                                                                                {el?.documents?.register_Directors && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    {" "}
                                                                                                    Register of directors{" "}
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents
                                                                                                        ?.register_Directors
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents
                                                                                                        ?.register_Directors
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents
                                                                                    ?.register_Shareholders && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <p>
                                                                                                    {" "}
                                                                                                    <strong>
                                                                                                        {" "}
                                                                                                        Register of shareholders{" "}
                                                                                                    </strong>{" "}
                                                                                                    :{" "}
                                                                                                </p>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el?.documents
                                                                                                            ?.register_Shareholders
                                                                                                    }
                                                                                                    download
                                                                                                    onClick={e => download(e)}
                                                                                                >
                                                                                                    Download
                                                                                                </a>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el.documents
                                                                                                            ?.register_Shareholders
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                >
                                                                                                    View Document
                                                                                                </a>
                                                                                            </Col>
                                                                                        </div>
                                                                                    )}
                                                                                {el?.documents?.Constitution && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    Constitution
                                                                                                </strong> :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.Constitution
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents?.Constitution
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents
                                                                                    ?.kyc_Individual_Register_Directors && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <p>
                                                                                                    {" "}
                                                                                                    <strong>
                                                                                                        {" "}
                                                                                                        KYC documents of individual
                                                                                                        shareholders in Register of
                                                                                                        Shareholders{" "}
                                                                                                    </strong>{" "}
                                                                                                    :{" "}
                                                                                                </p>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el?.documents
                                                                                                            ?.kyc_Individual_Register_Directors
                                                                                                    }
                                                                                                    download
                                                                                                    onClick={e => download(e)}
                                                                                                >
                                                                                                    Download
                                                                                                </a>{" "}
                                                                                            </Col>
                                                                                            <Col lg={4}>
                                                                                                {" "}
                                                                                                <a
                                                                                                    href={
                                                                                                        el.documents
                                                                                                            ?.kyc_Individual_Register_Directors
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                >
                                                                                                    View Document
                                                                                                </a>
                                                                                            </Col>
                                                                                        </div>
                                                                                    )}
                                                                                {el?.documents?.proof_Source_Funds && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    Proof of source of funds{" "}
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents
                                                                                                        ?.proof_Source_Funds
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents
                                                                                                        ?.proof_Source_Funds
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents?.kyc_UBOs && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    KYC documents for Ultimate
                                                                                                    Beneficial Owners (UBOs)
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el?.documents?.kyc_UBOs}
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el.documents?.kyc_UBOs}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                    <div style={{ marginTop: "5px" }}>
                                                                        <h5>Confirmation Details :</h5>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                marginTop: "20px",
                                                                            }}
                                                                        >
                                                                            <Col lg={12}>
                                                                                <p>
                                                                                    <strong>
                                                                                        The above information / disclosure
                                                                                        is true and correct
                                                                                    </strong>{" "}
                                                                                    :{" "}
                                                                                    {el?.confirmation
                                                                                        ?.informationOrdisclosure
                                                                                        ? "Yes"
                                                                                        : "No"}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>
                                                                                        I am the ultimate beneficial owner
                                                                                        of the company
                                                                                    </strong>{" "}
                                                                                    :{" "}
                                                                                    {el?.confirmation
                                                                                        ?.beneficialCompanyOwner
                                                                                        ? "Yes"
                                                                                        : "No"}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>
                                                                                        I am not acting in any capacity as a
                                                                                        nominee or agent of any undisclosed
                                                                                        principals :
                                                                                    </strong>
                                                                                    {el?.confirmation?.nomineeOragent
                                                                                        ? "Yes"
                                                                                        : "No"}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>
                                                                                        Any breach or non-disclosure to the
                                                                                        above may lead to the application
                                                                                        being voided or terminated.
                                                                                    </strong>{" "}
                                                                                    :{" "}
                                                                                    {el?.confirmation
                                                                                        ?.breachOrnonDisclosure
                                                                                        ? "Yes"
                                                                                        : "No"}
                                                                                </p>
                                                                            </Col>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        ))
                                                    ) : (
                                                        <div>
                                                            <p
                                                                colSpan="6"
                                                                style={{ textAlign: "center", padding: "20px" }}
                                                            >
                                                                Shareholders Details Not Available.
                                                            </p>
                                                        </div>
                                                    )}
                                                </Row>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <Card>
                                        <div style={{ padding: "20px" }}>
                                            <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                                Directors Details
                                            </p>
                                            {loading ? (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "20px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <ThreeDots
                                                        visible={true}
                                                        height="80"
                                                        width="80"
                                                        color="#6f42c1"
                                                        radius="9"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                            ) : (
                                                <Row>
                                                    {data && data?.directors?.length > 0 ? (
                                                        data?.directors?.map((el, i) => (
                                                            <>
                                                                <h5>
                                                                    {i + 1 == 1
                                                                        ? "First"
                                                                        : i + 1 == 2
                                                                            ? "Second"
                                                                            : "Third"}{" "}
                                                                    Directors
                                                                </h5>
                                                                <Col lg={12} style={{ marginTop: "5px" }}>
                                                                    <div>
                                                                        {/* <p>Documents :</p> */}
                                                                        <Col lg={6}>
                                                                            <div>
                                                                                {el?.documents?.consentForm && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                <strong> Consent Form</strong>:{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.consentForm
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.consentForm
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents?.passportOrNIC && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    {" "}
                                                                                                    Passport/NIC{" "}
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.passportOrNIC
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents?.passportOrNIC
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents?.proofOfAddress && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong>
                                                                                                    {" "}
                                                                                                    Proof of Address (less than 3
                                                                                                    months){" "}
                                                                                                </strong>{" "}
                                                                                                :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el?.documents?.proofOfAddress
                                                                                                }
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={
                                                                                                    el.documents?.proofOfAddress
                                                                                                }
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                                {el?.documents?.cv && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                        }}
                                                                                    >
                                                                                        {" "}
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <p>
                                                                                                {" "}
                                                                                                <strong> CV</strong> :{" "}
                                                                                            </p>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el?.documents?.cv}
                                                                                                download
                                                                                                onClick={e => download(e)}
                                                                                            >
                                                                                                Download
                                                                                            </a>{" "}
                                                                                        </Col>
                                                                                        <Col lg={4}>
                                                                                            {" "}
                                                                                            <a
                                                                                                href={el.documents?.cv}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                View Document
                                                                                            </a>
                                                                                        </Col>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        ))
                                                    ) : (
                                                        <div>
                                                            <p
                                                                colSpan="6"
                                                                style={{ textAlign: "center", padding: "20px" }}
                                                            >
                                                                Directors Details Not Available.
                                                            </p>
                                                        </div>
                                                    )}
                                                </Row>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                                <Card style={{ padding: "20px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                        Company Secretary Details
                                    </p>
                                    <Row
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Col lg={12} style={{ fontSize: "16px" }}>
                                            <span>
                                                1. I would like to request a Company Secretary from
                                                OnlineKorp:{" "}
                                            </span>{" "}
                                            {data?.secretary_Details?.isSecretary_OnlineKorp
                                                ? "YES"
                                                : "NO"}
                                        </Col>
                                        <Col lg={12} style={{ fontSize: "16px", marginTop: "5px" }}>
                                            <span>
                                                2. I shall not require any company secretarial services.
                                                The company is expected to have a turnover of less than
                                                100 million Mauritian Rupees :{" "}
                                            </span>
                                            {data?.secretary_Details?.isRequired_secretarial_Services
                                                ? "YES"
                                                : "NO"}
                                        </Col>
                                        <Col lg={12} style={{ fontSize: "16px", marginTop: "5px" }}>
                                            <span> 3. I shall provide a Company Secretary : </span>
                                            {data?.secretary_Details?.isProvide_secretary
                                                ? "YES"
                                                : "NO"}
                                        </Col>
                                        {data?.secretary_Details?.isProvide_secretary && (
                                            <Col lg={12} style={{ marginTop: "10px" }}>
                                                <div>
                                                    <h5
                                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                                    >
                                                        Secretary Documents
                                                    </h5>
                                                    <Col lg={10}>
                                                        <div>
                                                            {data?.secretary_Details?.documents?.passport && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Passport Document</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.secretary_Details?.documents
                                                                                    ?.passport
                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.secretary_Details?.documents
                                                                                    ?.passport
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                            {data?.secretary_Details?.documents
                                                                ?.proofOfAddress && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                {" "}
                                                                                <strong>
                                                                                    {" "}
                                                                                    Proof of Address (less than 3 months)
                                                                                </strong>{" "}
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.secretary_Details?.documents
                                                                                        ?.proofOfAddress
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.secretary_Details?.documents
                                                                                        ?.proofOfAddress
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.secretary_Details?.documents
                                                                ?.secretary_consentForm && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                {" "}
                                                                                <strong>
                                                                                    Upload Company secretary Consent form
                                                                                </strong>{" "}
                                                                                :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.secretary_Details?.documents
                                                                                        ?.secretary_consentForm
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.secretary_Details?.documents
                                                                                        ?.secretary_consentForm
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                            {data?.secretary_Details?.documents
                                                                ?.qualifications && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <p>
                                                                                {" "}
                                                                                <strong>
                                                                                    Upload Qualifications
                                                                                </strong> :{" "}
                                                                            </p>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.secretary_Details?.documents
                                                                                        ?.qualifications
                                                                                }
                                                                                download
                                                                                onClick={e => download(e)}
                                                                            >
                                                                                Download
                                                                            </a>{" "}
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            {" "}
                                                                            <a
                                                                                href={
                                                                                    data?.secretary_Details?.documents
                                                                                        ?.qualifications
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View Document
                                                                            </a>
                                                                        </Col>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Card>
                                <Card style={{ padding: "20px" }}>
                                    <p style={{ fontSize: "20px", fontWeight: "500" }}>
                                        Registered Office Address Details
                                    </p>
                                    <Row
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Col lg={12} style={{ fontSize: "16px" }}>
                                            <span>
                                                1. Request for OnlineKorp offices Mauritius to be the
                                                Official registered address:{" "}
                                            </span>{" "}
                                            {data?.registerOfficeDetails?.isRequest_OnlineKorp_offices
                                                ? "YES"
                                                : "NO"}
                                        </Col>
                                        <Col lg={12} style={{ fontSize: "16px", marginTop: "5px" }}>
                                            <span>
                                                2. Kindly upload CTC Utility Bill (not more than three
                                                months' old or certified true copy of the Lease
                                                agreement):{" "}
                                            </span>
                                            {data?.registerOfficeDetails?.utility_bill ? "YES" : "NO"}
                                        </Col>
                                        {data?.registerOfficeDetails?.utility_bill && (
                                            <Col lg={12} style={{ marginTop: "10px" }}>
                                                <div>
                                                    <h5
                                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                                    >
                                                        Utility Bill Documents
                                                    </h5>
                                                    <Col lg={10}>
                                                        <div>
                                                            {data?.registerOfficeDetails?.utility_bill_document && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <p>
                                                                            <strong>Utility Bill Document</strong>:{" "}
                                                                        </p>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.registerOfficeDetails?.utility_bill_document

                                                                            }
                                                                            download
                                                                            onClick={e => download(e)}
                                                                        >
                                                                            Download
                                                                        </a>{" "}
                                                                    </Col>
                                                                    <Col lg={4}>
                                                                        {" "}
                                                                        <a
                                                                            href={
                                                                                data?.registerOfficeDetails?.utility_bill_document

                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View Document
                                                                        </a>
                                                                    </Col>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        )}
                                        <Col lg={12} style={{ fontSize: "16px", marginTop: "5px" }}>
                                            <span>
                                                3. Accept Terms and Conditions and Privacy Policy of
                                                OnlineKorp :{" "}
                                            </span>
                                            {data?.isAccept_Term_Policy ? "YES" : "NO"}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyDetail
