import { VISA_COUNT, VISA_COUNT_API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  visaCount: 0,
}

const visaCount = (state = initialState, action) => {
  switch (action.type) {
    case VISA_COUNT:
      return {
        ...state,
        visaCount: action.payload,
      }
    case VISA_COUNT_API_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default visaCount
