import { GOAML_COUNT, GOAML_COUNT_API_ERROR } from "./actionTypes"

export const goAMLCount = goAMLcount => {
  console.log("user count details here.", goAMLcount)
  return {
    type: GOAML_COUNT,
    payload: goAMLcount,
  }
}

export const goAMLApiError = error => {
  return {
    type: GOAML_COUNT_API_ERROR,
    payload: error,
  }
}
