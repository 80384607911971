import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")

const FatcaAccountDetail = () => {
  const { fatcaAccountID } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true) // New loading state
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL

  document.title = "Service Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `${apiURL}/api/admin/fatca/account/details/${fatcaAccountID}`,
        {
          method: "GET", // GET is the default method, but it's good practice to specify it explicitly
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      console.log("res premium visa details", res)
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        console.log("res premium visa details", res.data)
        setData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false) // Set loading to false after fetching
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  console.log("company details Data", data)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card style={{ padding: "20px", marginTop: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  FATCA Account Report Details
                </p>
                {data ? (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg={4} style={{ fontSize: "16px" }}>
                        {" "}
                        <strong> GIIN : </strong>{" "}
                        <span style={{ textAlign: "end" }}>{data?.GIIN} </span>
                      </Col>
                      <Col lg={4} style={{ fontSize: "16px" }}>
                        {" "}
                        <strong>
                          Application Status By OnlineKorp :{" "}
                        </strong>{" "}
                        {data?.active}
                      </Col>
                      <Col lg={4} style={{ fontSize: "16px" }}>
                        <strong>Application Form Status :</strong>{" "}
                        {data?.status}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Financial Institution :</strong>{" "}
                        {data?.financialInstitution
                          ? data?.financialInstitution
                          : "-"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Filer Category :</strong>{" "}
                        {data?.filerCategory ? data?.filerCategory : "-"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Receiving Country :</strong>{" "}
                        {data?.receivingCountry ? data?.receivingCountry : "-"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> Applied Date : </strong>
                        {data?.createdAt?.substring(0, 10)}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Transmitting Country :</strong>{" "}
                        {data?.transmittingCountry
                          ? data?.transmittingCountry
                          : "-"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> Reporting Period : </strong>
                        {data?.reportingPeriod?.substring(0, 10)}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> XML Report Downloaded : </strong>
                        {data?.status === "Incomplete" ? "No" : "YES"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Tax Residency :</strong>{" "}
                        {data?.taxResidency ? data?.taxResidency : "-"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> country : </strong>
                        {data?.country}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>city :</strong> {data?.city ? data?.city : "-"}
                      </Col>
                      <Col
                        lg={4}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> Address : </strong>
                        {data?.address}
                      </Col>
                    </Row>
                    <hr style={{ margin: "30px 0" }} />{" "}
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col lg={12}>
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          Account Details :
                        </p>
                        {data?.account?.map((account, index) => (
                          <div key={index}>
                            <Row
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Col lg={4} style={{ fontSize: "16px" }}>
                                <strong>Account Number:</strong>{" "}
                                {account?.account_Information?.accountNumber}
                              </Col>
                              <Col lg={4} style={{ fontSize: "16px" }}>
                                <strong>Account Status:</strong>{" "}
                                {account?.account_Information?.status}
                              </Col>
                              <Col lg={4} style={{ fontSize: "16px" }}>
                                <strong>Account Type:</strong>{" "}
                                {account?.account_Information?.accountType}
                              </Col>
                              <Col
                                lg={4}
                                style={{ fontSize: "16px", marginTop: "10px" }}
                              >
                                <strong>Account Currency:</strong>{" "}
                                {account?.account_Balance_Payment?.currency}
                              </Col>
                              <Col
                                lg={4}
                                style={{ fontSize: "16px", marginTop: "10px" }}
                              >
                                <strong>Balance (CLP):</strong>{" "}
                                {account?.account_Balance_Payment?.balances}
                              </Col>
                              <Col
                                lg={4}
                                style={{ fontSize: "16px", marginTop: "10px" }}
                              >
                                <strong>Dividends (CLP):</strong>{" "}
                                {account?.account_Balance_Payment?.dividends}
                              </Col>
                              <Col
                                lg={4}
                                style={{ fontSize: "16px", marginTop: "10px" }}
                              >
                                <strong>Interest (CLP):</strong>{" "}
                                {account?.account_Balance_Payment?.interest}
                              </Col>
                              <Col
                                lg={4}
                                style={{ fontSize: "16px", marginTop: "10px" }}
                              >
                                <strong>Other Income (CLP):</strong>{" "}
                                {account?.account_Balance_Payment?.otherIncome}
                              </Col>
                              <Col
                                lg={4}
                                style={{ fontSize: "16px", marginTop: "10px" }}
                              >
                                <strong>Proceeds (CLP):</strong>{" "}
                                {account?.account_Balance_Payment?.proceeds}
                              </Col>
                            </Row>
                            <hr style={{ margin: "30px 0" }} />{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Account Holders :
                            </p>
                            {account?.holders?.map((holder, idx) => (
                              <Row
                                key={idx}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <strong>Account Holder Type:</strong>{" "}
                                  {holder?.accountHolderType}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <strong>Account Holder Name:</strong>{" "}
                                  {holder?.firstName} {holder?.lastName}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>DOB:</strong> {holder?.dob}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>Tax ID Number:</strong>{" "}
                                  {holder?.taxIdentificationNumber}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>Tax Residence Country:</strong>{" "}
                                  {holder?.taxResidenceCountry}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>TIN Issuing Country:</strong>{" "}
                                  {holder?.tinIssuingCountry}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>Country:</strong> {holder?.country}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>Address:</strong> {holder?.address}
                                </Col>
                                <Col
                                  lg={4}
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <strong>City:</strong> {holder?.city}
                                </Col>
                              </Row>
                            ))}
                            <hr style={{ margin: "30px 0" }} />{" "}
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Substantial Owner :
                            </p>
                            {account?.holders?.map((holder, idx) =>
                              holder?.substantial?.map(
                                (controlling, ctrIdx) => (
                                  <Row
                                    key={ctrIdx}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {controlling?.firstName &&
                                      controlling?.lastName && (
                                        <Col
                                          lg={4}
                                          style={{
                                            fontSize: "16px",
                                          }}
                                        >
                                          <strong>
                                            Controlling Person Name:
                                          </strong>{" "}
                                          {controlling?.firstName}{" "}
                                          {controlling?.lastName}
                                        </Col>
                                      )}

                                    {controlling?.dob && (
                                      <Col
                                        lg={4}
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        <strong>DOB:</strong> {controlling?.dob}
                                      </Col>
                                    )}
                                    {controlling?.organisationName && (
                                      <Col
                                        lg={4}
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        <strong>Organisation Name:</strong>{" "}
                                        {controlling?.organisationName}
                                      </Col>
                                    )}

                                    {controlling?.organisationType && (
                                      <Col
                                        lg={4}
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        <strong>Organisation Type:</strong>{" "}
                                        {controlling?.organisationType}
                                      </Col>
                                    )}

                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>Tax ID:</strong>{" "}
                                      {controlling?.taxIdentificationNumber}
                                    </Col>
                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>Tax Residence Country:</strong>{" "}
                                      {controlling?.taxResidenceCountry}
                                    </Col>
                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>TIN Issuing Country:</strong>{" "}
                                      {controlling?.tinIssuingCountry}
                                    </Col>
                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>Controlling Person Type:</strong>{" "}
                                      {controlling?.ControllingPersonType}
                                    </Col>
                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>Address:</strong>{" "}
                                      {controlling?.address}
                                    </Col>
                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>City:</strong> {controlling?.city}
                                    </Col>
                                    <Col
                                      lg={4}
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <strong>Country:</strong>{" "}
                                      {controlling?.country}
                                    </Col>
                                  </Row>
                                )
                              )
                            )}
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div>
                    <p
                      colSpan="6"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      Applicant Details Not Available.
                    </p>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default FatcaAccountDetail
