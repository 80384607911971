import { useEffect, useState } from "react"
import { Button, Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")
import { jsPDF } from "jspdf"
import html2pdf from "html2pdf.js"
import JSZip from "jszip"
import { saveAs } from "file-saver"

const VATDetail = () => {
  const { vatID } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL

  document.title = "Residential VAT-REFUND Service Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `${apiURL}/api/admin/vat-refund/details/${vatID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()
      console.log("goAmlID details", res)
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        console.log("res premium visa details", res.data)
        setData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  const download = e => {
    e.preventDefault()

    const url = e.target.href
    console.log(url)

    fetch(url, {
      method: "GET",
      headers: {
        // You can add custom headers here if needed
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.arrayBuffer()
      })
      .then(buffer => {
        const blob = new Blob([buffer])
        const link = document.createElement("a")
        const fileName = url.split("/").pop() // Extract file name from URL
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName // Use the extracted file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Clean up
        window.URL.revokeObjectURL(link.href) // Release the object URL
      })
      .catch(err => {
        console.error("Download failed:", err)
      })
  }

  // Function to download PDF
  const downloadPDF = () => {
    const element = document.getElementById("pdf-content") // ID of the HTML element to be captured
    // Using html2pdf.js to generate and download the PDF
    const options = {
      filename: "Residential-VAT-Refund-service-details.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }
    html2pdf().from(element).set(options).save()
    downloadAllDocuments()
  }

  const downloadAllDocuments = async () => {
    const zip = new JSZip()

    // Define the files to download (person and organization documents)
    const filesToDownload = [
      {
        name: "proofOfAddress",
        label: "Proof of Address",
      },
      {
        name: "marriage_Certificate",
        label: "Marriage Certificate",
      },
      {
        name: "building_LandPermit",
        label: "Building Land Permit",
      },
      {
        name: "receiptOfpaymentIssued",
        label: "Receipt of Payment",
      },
      {
        name: "spouse_Passport_NID_NCID",
        label: "Spouse Passport NID NCID",
      },
      {
        name: "evidenceOfIncome",
        label: "Evidence Of Income",
      },
      {
        name: "contract_Agreement_with_building_contractor_property_developer",
        label: "Contract Agreement with building Contractor Property Developer",
      },
      {
        name: "Construction Plan",
        label: "constructionPlan",
      },
      {
        name: "applicant_Passport_NID_NCID",
        label: "Applicant Passport NID NCID",
      },
      {
        name: "VATInvoicesSection20",
        label: "VAT Invoices Section 20",
      },
    ]

    // Define folder for VAT invoices
    const vatInvoiceFolder = zip.folder("vatInvoice")

    // Check if vatInvoice exists and add it to the zip file under the "vatInvoice" folder
    const vatInvoiceUrl = data?.vatInvoice
    if (vatInvoiceUrl) {
      const response = await fetch(vatInvoiceUrl)

      // Get the Content-Type from the response headers
      const contentType = response.headers.get("Content-Type")

      // Determine file extension based on Content-Type
      let fileExtension = "pdf" // Default is pdf

      if (contentType.includes("image")) {
        if (contentType.includes("jpeg")) {
          fileExtension = "jpg"
        } else if (contentType.includes("png")) {
          fileExtension = "png"
        }
      }

      const blob = await response.blob()
      // Add the vatInvoice to the "vatInvoice" folder with a specific name
      vatInvoiceFolder.file("vatInvoice.pdf", blob)
    }

    // Iterate through the other files and add them to the zip
    for (let file of filesToDownload) {
      const fileUrl = data?.documents?.[file.name] // Dynamically fetch the URL from the data object

      if (fileUrl) {
        const response = await fetch(fileUrl)

        // Get the Content-Type from the response headers
        const contentType = response.headers.get("Content-Type")

        // Determine file extension based on Content-Type
        let fileExtension = "pdf" // Default is pdf

        if (contentType.includes("image")) {
          if (contentType.includes("jpeg")) {
            fileExtension = "jpg"
          } else if (contentType.includes("png")) {
            fileExtension = "png"
          } else if (contentType.includes("gif")) {
            fileExtension = "gif"
          }
        }

        const blob = await response.blob()
        // Add the other files to the root of the zip (not inside the "vatInvoice" folder)
        zip.file(`${file.label}.${fileExtension}`, blob)
      }
    }

    // Generate the ZIP file and trigger the download
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "Residential-VAT-Refund-documents.zip")
    })
  }

  console.log("visa Data", data)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div
            className="mt-4"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button className="p-2" color="success" onClick={downloadPDF}>
              Download Form
            </Button>
          </div>
          <div className="user-details">
            <Row id="pdf-content">
              <Col lg={12}>
                <Card style={{ padding: "20px", marginTop: "20px" }}>
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>
                    Residential VAT-Refund Service Details
                  </p>
                  {data ? (
                    <>
                      <Row>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          {" "}
                          <strong> Jurisdiction : </strong>{" "}
                          <span style={{ textAlign: "end" }}>
                            {data?.jurisdiction}{" "}
                          </span>
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          {" "}
                          <strong>
                            Application Status By OnlineKorp :{" "}
                          </strong>{" "}
                          {data?.active}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong>Application Form Status :</strong>{" "}
                          {data?.status}
                        </Col>
                        <Col
                          lg={6}
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          <strong> Applied Date : </strong>
                          {data?.createdAt?.substring(0, 10)}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div>
                      <p
                        colSpan="6"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        Applicant Details Not Available.
                      </p>
                    </div>
                  )}
                </Card>
                <Card style={{ padding: "20px" }}>
                  {data ? (
                    <>
                      <Row>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>
                          Applicant Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>First Name : </strong>{data?.applicant?.firstName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Last Name : </strong>{data?.applicant?.lastName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                          <strong>TAN Number : </strong>{data?.applicant?.tanNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                          <strong>Email : </strong>{data?.applicant?.email}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                          <strong>Marital Status : </strong>{data?.applicant?.maritalStatus}
                        </Col>

                        <hr style={{ marginTop: "20px" }}/>

                        <p style={{ fontSize: "18px", fontWeight: "500", marginTop: "10px" }}>
                          Contact & Address Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Phone Number : </strong>{data?.contact?.mobileNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Address : </strong>{data?.address?.address}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "5px" }}>
                          <strong>City : </strong>{data?.address?.city}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "5px" }}>
                          <strong>Town : </strong>{data?.address?.town}
                        </Col>

                        <hr style={{ marginTop: "20px" }}/>

                        <p style={{ fontSize: "18px", fontWeight: "500", marginTop: "10px" }}>
                          Contract Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>BRN Number : </strong>{data?.contractDetails?.BRN}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>NID Number : </strong>{data?.contractDetails?.NIDNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "10px" }}>
                          <strong>Contractor Name : </strong>{data?.contractDetails?.contractorName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "10px" }}>
                          <strong>Completion Date : </strong>{data?.contractDetails?.dateOfCompletion}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "10px" }}>
                          <strong>Start Date : </strong>{data?.contractDetails?.dateOfStart}
                        </Col>

                        <hr style={{ marginTop: "20px" }}/>

                        <Row>
                          <p style={{ fontSize: "18px", marginTop: "20px", fontWeight: "500" }}>
                            Applicant Income Details
                          </p>
                          <Col lg={12}>
                            <table style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}>
                              <thead>
                                <tr>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Employment Period</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Employer Name</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Employer Registration No</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Annual Net Income</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.applicantIncome?.map((income, index) => (
                                  <tr key={income._id}>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.employementPeriod}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.employerName}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.employerRegistrationNo}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.annualNetIncome}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>

                  

                        <p style={{ fontSize: "18px", fontWeight: "500", marginTop: "20px" }}>
                          Bank Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Bank Name : </strong>{data?.bankDetails?.bankName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Bank Account Number : </strong>{data?.bankDetails?.bankAccountNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "10px" }}>
                          <strong>Applicant & Spouse Annual Net Income : </strong>{data?.aggregateAnnualNetIncome}
                        </Col>

                        <hr style={{ marginTop: "20px" }} />

                        <p style={{ fontSize: "18px", marginTop: "10px", fontWeight: "500" }}>
                          Spouse Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>First Name : </strong>{data?.spouse?.firstName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Last Name : </strong>{data?.spouse?.lastName}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                          <strong>TAN Number : </strong>{data?.spouse?.tanNo}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px", marginTop: "8px" }}>
                          <strong>Email : </strong>{data?.spouse?.email}
                        </Col>

                        <hr style={{ marginTop: "20px" }}/>

                        <p style={{ fontSize: "18px", marginTop: "10px", fontWeight: "500" }}>
                          Residential Building Details
                        </p>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Cost Agreement : </strong>{data?.residentialBuilding?.costAgreement}
                        </Col>
                        <Col lg={6} style={{ fontSize: "16px" }}>
                          <strong>Date Of Purchase : </strong>{data?.residentialBuilding?.dateOfPurchase}
                        </Col>

                        <hr style={{ marginTop: "20px" }}/>

                        <Row>
                          <p style={{ fontSize: "18px", marginTop: "10px", fontWeight: "500" }}>
                            Spouse Income Details
                          </p>
                          <Col lg={12}>
                            <table style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}>
                              <thead>
                                <tr>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Employment Period</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Employer Name</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Employer Registration No</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Annual Net Income</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.spouseIncome?.map((income, index) => (
                                  <tr key={income._id}>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.employementPeriod}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.employerName}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.employerRegistrationNo}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{income.annualNetIncome}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>

                        <Row>
                          <p style={{ fontSize: "18px", marginTop: "20px", fontWeight: "500" }}>
                            VAT Invoice Details
                          </p>
                          <Col lg={12}>
                            <table style={{ width: "100%", border: "1px solid #ddd", borderCollapse: "collapse" }}>
                              <thead>
                                <tr>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Serial Number</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>VAT Invoice No</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Date</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>VAT Paid</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>Date of Payment</th>
                                  <th style={{ padding: "8px", border: "1px solid #ddd" }}>VAT Refundable</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.invoicesDetails?.map(invoice => (
                                  <tr key={invoice._id}>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{invoice.sn}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{invoice.VATInvoiceNo}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{invoice.date}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{invoice.VATPaid}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{invoice.dateOfPayment}</td>
                                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>{invoice.VatRefundable}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </Row>

                      <Row>
                        <Card style={{ padding: "20px", marginTop: "20px" }}>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              marginTop: "20px",
                            }}
                          >
                            Documents
                          </p>
                          <Col lg={12}>
                            {Object.entries(data?.documents || {}).map(
                              ([key, value]) => {
                                if (value) {
                                  const documentLabel = key
                                    .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Add space before uppercase letters
                                    .replace(/_/g, " ") // Replace underscores with spaces
                                    .replace(/\b\w/g, char =>
                                      char.toUpperCase()
                                    ) // Capitalize each word's first letter

                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                      }}
                                      key={key}
                                    >
                                      <Col lg={6}>
                                        <p>
                                          <strong>{documentLabel}</strong> :
                                        </p>
                                      </Col>
                                      <Col lg={3}>
                                        <a
                                          href={value}
                                          download
                                          onClick={e => download(e)}
                                          style={{
                                            textDecoration: "none",
                                            color: "blue",
                                          }}
                                        >
                                          Download
                                        </a>
                                      </Col>
                                      <Col lg={3}>
                                        <a
                                          href={value}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            textDecoration: "none",
                                            color: "blue",
                                          }}
                                        >
                                          View Document
                                        </a>
                                      </Col>
                                    </div>
                                  )
                                }
                                return null // Skip rendering if document URL is empty
                              }
                            )}
                          </Col>
                        </Card>
                        {data?.vatInvoice && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <Col lg={4}>
                              {" "}
                              <p>
                                <strong>VAT Invoice</strong>:{" "}
                              </p>{" "}
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <a
                                href={data?.vatInvoice}
                                download
                                onClick={e => download(e)}
                              >
                                Download
                              </a>{" "}
                            </Col>
                            <Col lg={4}>
                              {" "}
                              <a
                                href={data?.vatInvoice}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Document
                              </a>
                            </Col>
                          </div>
                        )}
                      </Row>
                    </>
                  ) : (
                    <div>
                      <p
                        colSpan="6"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        Service Details Not Available.
                      </p>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default VATDetail
