import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")

const ContactDetail = () => {
  const { userId } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL

  document.title = "Contact Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        `${apiURL}/api/admin/contact/details/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const res = await response.json()

      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }

        setData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card style={{ padding: "20px", marginTop: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  Contact Details
                </p>
                {loading ? (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#6f42c1"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                ) : data ? (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        {" "}
                        <strong> Name : </strong>{" "}
                        <span style={{ textAlign: "end" }}>
                          {data?.senderName}{" "}
                        </span>
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        {" "}
                        <strong>Email : </strong> {data?.senderEmail}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Phone Number :</strong>
                        {data?.contact?.country_code} ${data?.contact?.phone}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong> Applied Date : </strong>
                        {data?.createdAt?.substring(0, 10)}
                      </Col>
                      <Col
                        lg={6}
                        style={{ fontSize: "16px", marginTop: "10px" }}
                      >
                        <strong>Description :</strong> {data?.desc}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div>
                    <p
                      colSpan="6"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      Contact Details Not Available.
                    </p>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ContactDetail
