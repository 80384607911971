import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import userSaga from "./user/saga"
import goAmlSaga from "./goAml/saga"
import companySaga from "./company/saga"
import visaSaga from "./visa/saga"
import vatSaga from "./vat/saga"
import permit from "./workPermit/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    ProfileSaga(),
    LayoutSaga(),
    userSaga(),
    goAmlSaga(),
    companySaga(),
    visaSaga(),
    vatSaga(),
    permit()
  ])
}
