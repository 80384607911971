import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class dountchart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        colors: [
          "#FF9F00",
          "#3C8DAD",
          "#1F77B4",
          "#E94E77",
          "#50C878",
          "#FF6F61",
          "#FF8A81",
        ],
        labels: [
          "Premium Visa",
          "GoAML",
          "Incorporate Domestic Company",
          "CRS-NIL",
          "FATCA-NIL",
          "Work Permit",
          "Residential VAT-Refund"
        ],
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "16px",
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#fff"],
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45,
          },
        },
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          fontSize: "20px",
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          itemSpacing: 25,
          labels: {
            fontSize: "20px",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%", // Adjusts the size of the donut hole
              labels: {
                show: false,
                name: {
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#000",
                },
                value: {
                  fontSize: "16px",
                  color: "#000",
                },
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [],
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const services = Object.keys(this.props.data)
      const seriesData = []

      services.forEach(service => {
        const approved = this.props.data[service]?.Approved || 0
        const pending = this.props.data[service]?.Pending || 0
        const rejected = this.props.data[service]?.Rejected || 0
        const total = approved + pending + rejected
        seriesData.push(total)
      })
      this.setState({
        series: seriesData,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height="300"
        />
      </React.Fragment>
    )
  }
}

export default dountchart
