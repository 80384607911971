import { MDBDataTable } from "mdbreact"
import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const Contact = () => {
    const [contact, setContact] = useState([])
    const [loading, setLoading] = useState(true)
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const apiURL = process.env.REACT_APP_BACKEND_URL

    document.title = "Contact List | Admin & Dashboard"

    const fetchContacts = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${apiURL}/api/admin/contact`, {
                method: "GET", // GET is the default method, but it's good practice to specify it explicitly
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const res = await response.json()
            console.log("res premium visa details", res)
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                console.log("res premium visa details", res.data)
                setContact(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    useEffect(() => {
        fetchContacts()
    }, [])

    const handleDetails = (userId) => {
        return navigate(`/contact/details/${userId}`)
    }

    const toggle = async (userID) => {
        console.log("resoved id here", userID);
        try {
            const apiUrl = `${apiURL}/api/admin/service/contact/${userID}`
            const response = await fetch(apiUrl, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log("Status updated successfully:", response)
            if (!response.ok) {
                throw new Error("Network response was not ok")
            }
            const data = await response.json()
            toast("Status Updated successfully!")
            fetchContacts()
            console.log("Application Accepted successfully:", data)
        } catch (error) {
            console.error("Error updating status:", error)
        }
    }

    const rows = contact?.map((user, i) => ({
        ...user,
        ID: i + 1,
        fullName: (
            <p
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => handleDetails(user._id)}
            >
                {user?.senderName}
            </p>
        ),
        email: user?.senderEmail,
        description: user?.desc,
        createdAt: user.createdAt?.substring(0, 10),
        mobile: `${user?.contact?.country_code} ${user?.contact?.phone}`,
        actions: (

            <Button
                type="button"
                color="success"
                style={{ fontSize: "12px" }}
                onClick={() => toggle(user?._id)}
            >
                {user?.isResolved ? "Mark as Unresolved" : "Mark as Resolved"}

            </Button>

        ),
    }))

    //datatables here.
    const data = {
        columns: [
            {
                label: "#ID",
                field: "ID",
                sort: "asc",
                width: 20,
            },
            {
                label: "Full Name",
                field: "fullName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Description",
                field: "description",
                sort: "asc",
                width: 100,
            },
            {
                label: "Mobile No",
                field: "mobile",
                sort: "asc",
                width: 100,
            },
            {
                label: "Action",
                field: "actions",
            },
        ],
        rows,
    }

    console.log("company details Data", data)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        maintitle="OnlineKorp"
                        title="Contact"
                        breadcrumbItem="Contact"
                    />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        {loading ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#6f42c1"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            <MDBDataTable responsive striped bordered data={data} />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Contact
