import { Button, Card, CardBody, Col, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useEffect, useState } from "react"
import Status from "components/Common/model/Status"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { ThreeDots } from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"
import { useDispatch } from "react-redux"
import * as XLSX from "xlsx"
import moment from "moment"

const GoAML = () => {
    const [modal, setModal] = useState(false)
    const [updateID, setUpdateID] = useState(null)
    const [selectedGroup, setselectedGroup] = useState(null)
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const apiURL = process.env.REACT_APP_BACKEND_URL
    const [visaList, setVisaList] = useState([])

    document.title = "GoAML Services | Admin & Dashboard"
    const toggle = (itemID = null) => {
        setModal(!modal)
        setUpdateID(itemID)
    }

    const fetchPremiumlist = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${apiURL}/api/admin/goAml`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const res = await response.json()
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                setVisaList(res.data)
                console.log(
                    "res premium visa list.................................",
                    res
                )
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    const handleStatus = async id => {
        console.log("status updated ID", id, selectedGroup.value)
        try {
            // Define the API endpoint
            const apiUrl = `${apiURL}/api/admin/goaml/status/${id}`
            const response = await fetch(apiUrl, {
                method: "PATCH", // or 'PATCH' if you're using PATCH
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ status: selectedGroup.value }),
            })
            console.log("Status updated successfully:", response)
            if (!response.ok) {
                setModal(!modal)
                throw new Error("Network response was not ok")
            }
            const data = await response.json()
            setModal(!modal)
            // alert("Status Updated Successfully.")
            toast("Status updated successfully!")
            fetchPremiumlist()
            console.log("Status updated successfully:", data)
        } catch (error) {
            console.error("Error updating status:", error)
        }
    }

    const toggleForm = async userID => {
        console.log("status updated ID", userID)
        try {
            const apiUrl = `${apiURL}/api/admin/service/accept/${userID}?servicename=goAML`
            const response = await fetch(apiUrl, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log("Status updated successfully:", response)
            if (!response.ok) {
                throw new Error("Network response was not ok")
            }
            const data = await response.json()
            toast("Application Accepted successfully!")
            fetchPremiumlist()
            console.log("Application Accepted successfully:", data)
        } catch (error) {
            console.error("Error updating status:", error)
        }
    }

    const handleDetails = async goAmlID => {
        console.log("visa services details here.", goAmlID)
        return navigate(`/service/details/${goAmlID}`)
    }

    useEffect(() => {
        fetchPremiumlist()
    }, [])

    const rows = visaList?.map((user, i) => ({
        ...user,
        ID: i + 1,
        fullName: (
            <p
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => handleDetails(user._id)}
            >
                {user?.userId?.fullName}
            </p>
        ),
        email: user?.userId?.email,
        createdAt: user.createdAt?.substring(0, 10),
        actions: (
            <div className="d-flex gap-2">
                <Button
                    type="button"
                    color="success"
                    style={{ fontSize: "12px" }}
                    disabled={!user?.isAccepted || user?.status === "Incomplete"}
                    onClick={() => toggle(user?._id)}
                >
                    Update Status
                </Button>
                <Button
                    type="button"
                    color="success"
                    style={{ fontSize: "12px" }}
                    disabled={user?.isAccepted || user?.status === "Incomplete"}
                    onClick={() => toggleForm(user?._id)}
                >
                    {user?.isAccepted ? "Accepted" : "Accept Form"}
                </Button>
            </div>
        ),
    }))

    const [filteredRows, setFilteredRows] = useState(rows)
    const [statusFilter, setStatusFilter] = useState("")
    const [formDate, setFormDate] = useState("")
    const [toDate, setToDate] = useState("")

    const handleDownloadExcel = () => {
        const selectedFields = filteredRows.map(user => ({
            ID: user.ID,
            fullName: user.fullName.props.children,
            email: user.email,
            Country: user.Country,
            createdAt: user.createdAt,
            status: user.status,
            packageType: user.package?.packageType || "N/A",
            packagePrice: user.package?.packagePrice || 0,
        }))

        // Calculate the total sum of package prices
        const totalPackagePrice = selectedFields.reduce(
            (sum, user) => sum + Number(user.packagePrice),
            0
        )
        // Add the total sum row at the end of the filtered data
        selectedFields.push({
            ID: "Total",
            fullName: "",
            email: "",
            Country: "",
            createdAt: "",
            status: "",
            packageType: "",
            packagePrice: totalPackagePrice,
        })

        // Convert the selected fields to an Excel worksheet
        const worksheet = XLSX.utils.json_to_sheet(selectedFields)
        // Apply column widths and styling (same as before)
        const columnWidths = [
            { wch: 10 }, // ID column width
            { wch: 25 }, // fullName column width
            { wch: 40 }, // email column width
            { wch: 15 }, // Country column width
            { wch: 15 }, // createdAt column width
            { wch: 15 }, // status column width
            { wch: 25 }, // packageType column width
            { wch: 15 }, // packagePrice column width
        ]
        worksheet["!cols"] = columnWidths
        // Apply header row styling
        const headerStyle = {
            font: { sz: 14, bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "4F81BD" } }, // Blue background
            alignment: { vertical: "center", horizontal: "center", wrapText: true },
            border: {
                top: { style: "thin" },
                right: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
            },
        }
        const range = XLSX.utils.decode_range(worksheet["!ref"])
        for (let col = range.s.c; col <= range.e.c; col++) {
            const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })]
            if (headerCell) {
                headerCell.s = headerStyle
            }
        }

        // Apply general cell style (alignment, borders, etc.)
        for (let row = range.s.r + 1; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })]
                if (cell) {
                    cell.s = {
                        font: { sz: 12, bold: false },
                        alignment: {
                            vertical: "center",
                            horizontal: col === 7 || col === 8 ? "right" : "center",
                            wrapText: true,
                        },
                        border: {
                            top: { style: "thin" },
                            right: { style: "thin" },
                            bottom: { style: "thin" },
                            left: { style: "thin" },
                        },
                    }
                }
            }
        }
        // Create a new workbook
        const workbook = XLSX.utils.book_new()
        // Append the styled worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "GOAML-Excel")
        // Write the workbook to an Excel file and trigger download
        const fileName = `GOAML-Excel-${Date.now()}.xlsx`
        XLSX.writeFile(workbook, fileName)
        setStatusFilter("")
        setFormDate(null)
        setToDate(null)
        setFilteredRows(rows)
    }

    useEffect(() => {
        const filterRows = () => {
            const filtered = rows.filter(user => {
                const isWithinDateRange =
                    (formDate ? moment(user.createdAt).unix() >= formDate : true) &&
                    (toDate ? moment(user.createdAt).unix() <= toDate : true)
                const statusMatches = statusFilter ? user.active === statusFilter : true
                return isWithinDateRange && statusMatches
            })
            setFilteredRows(filtered)
        }

        filterRows()
    }, [visaList, formDate, toDate, statusFilter])

    //datatables here.
    const data = {
        columns: [
            {
                label: "#ID",
                field: "ID",
                sort: "asc",
                width: 20,
            },
            {
                label: "Full Name",
                field: "fullName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Jurisdiction",
                field: "jurisdiction",
                sort: "asc",
                width: 100,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },
            {
                label: "Active",
                field: "active",
                sort: "asc",
                width: 100,
            },
            {
                label: "Create Date",
                field: "createdAt",
                sort: "asc",
                width: 100,
            },
            {
                label: "Action",
                field: "actions",
                // sort:"asc",
                width: 150,
            },
        ],
        rows: filteredRows,
    }

    console.log("infinity running.", formDate, toDate)

    const handleReset = () => {
        setStatusFilter("")
        setFormDate(null)
        setToDate(null)
        setFilteredRows(rows)
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        maintitle="OnlineKorp"
                        title="GOAML"
                        breadcrumbItem="GOAML Services"
                    />
                    <Row
                        style={{
                            marginBottom: "20px",
                            padding: "5px",
                        }}
                    >
                        <Col
                            lg={3}
                            style={{ display: "flex", flexDirection: "column", gap: "3px" }}
                        >
                            <label>From Date</label>
                            <input
                                type="date"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={e => setFormDate(moment(e.target.value).unix())}
                                style={{ padding: "10px", border: "none", borderRadius: "8px" }}
                            />
                        </Col>
                        <Col
                            lg={3}
                            style={{ display: "flex", flexDirection: "column", gap: "3px" }}
                        >
                            <label>To Date</label>
                            <input
                                type="date"
                                max={moment().format("YYYY-MM-DD")}
                                onChange={e => setToDate(moment(e.target.value).unix())}
                                style={{ padding: "10px", border: "none", borderRadius: "8px" }}
                            />
                        </Col>

                        <Col
                            lg={3}
                            style={{ display: "flex", flexDirection: "column", gap: "3px" }}
                        >
                            <label>Application Status</label>
                            <select
                                onChange={e => setStatusFilter(e.target.value)}
                                value={statusFilter}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "none",
                                    borderRadius: "8px",
                                }}
                            >
                                <option value="">All Statuses</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Pending">Pending</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </Col>
                        <Col lg={3}>
                            <div className="d-flex gap-1" style={{ marginTop: "35px" }}>
                                <Button
                                    color="success d-flex gap-2"
                                    style={{ alignItems: "center" }}
                                    onClick={handleReset}
                                >
                                    Reset Filter <i className="ti-filter"></i>
                                </Button>
                                <Button
                                    color="primary d-flex gap-2"
                                    style={{ alignItems: "center" }}
                                    onClick={handleDownloadExcel}
                                >
                                    Download Excel <i className="ti-download"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        {loading ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#6f42c1"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            <MDBDataTable responsive striped bordered data={data} />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                <Status
                    modal={modal}
                    toggle={toggle}
                    id={updateID}
                    selectedGroup={selectedGroup}
                    setselectedGroup={setselectedGroup}
                    handleStatus={handleStatus}
                />
            }
        </>
    )
}

export default GoAML
