import { call, put, takeEvery } from "redux-saga/effects"
import { KYCApiError } from "./actions"
import { KYC_COUNT } from "./actionTypes"

function* KYCTotalCount({ payload }) {
  try {
  } catch (error) {
    yield put(KYCApiError(error.message))
    if (error.message === "Token verification failed") {
      history.push("/login")
    }
  }
}

function* KYCSaga() {
  yield takeEvery(KYC_COUNT, KYCTotalCount)
}

export default KYCSaga
