import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  companyCount,
  goAMLCount,
  KYCCount,
  permitCount,
  userApiError,
  userCount,
  vatCount,
  visaCount,
} from "store/actions"

document.title = "OnlineKorp | Admin & Dashboard"

const App = () => {
  const token = localStorage.getItem("Admintoken")
  const apiURL = process.env.REACT_APP_BACKEND_URL
  const dispatch = useDispatch()

  const fetchUserCount = async () => {
    const response = await fetch(`${apiURL}/api/admin/dashboard/count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) {
      throw new Error("Network response was not ok")
    }
    const res = await response.json()
    if (res.message === "Token verification failed") {
      throw new Error("Token verification failed")
    }
    return res.count
  }

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const count = await fetchUserCount()
        console.log("all count here", count)
        dispatch(goAMLCount(count?.goAMLCount))
        dispatch(userCount(count?.userCount))
        dispatch(companyCount(count?.companyCount))
        dispatch(visaCount(count?.visaCount))
        dispatch(vatCount(count?.vatCount))
        dispatch(permitCount(count?.permitCount))
        dispatch(KYCCount(count?.KYCCount))
      } catch (error) {
        dispatch(userApiError(error.message))
      }
    }
    fetchCount()
    // const intervalId = setInterval(() => {
    //   fetchCount();
    // }, 5000);

    // Cleanup interval when component is unmounted
    // return () => clearInterval(intervalId);
  }, [])

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<Authmiddleware>{route.component}</Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
